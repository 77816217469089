.dashboard_grid {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    min-height: 80vh;
    margin-right: 2rem;
}

.update_block {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.stats_block {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* margin: 0 1rem; */
}

.dashboard_cal_block {
    display: flex;
    flex-direction: column;
    width: 300px;
}




/* DASHBOARD CAL */

.dashboard-cal-cell {
    position: relative;
    width: 100%;
    height: 150px;
    border-top: 1px solid var(--dim-grey);
    padding: 5px;
    background-color: #f8f8f8;
    overflow: hidden;
}

.dashboard-cal-cell:hover {
    overflow: visible;
}

.dashboard-cal-cell.dashboard-cal-today {
    border-top: 2px solid var(--blue);
    background-color: var(--dim-grey) !important;
    color: var(--blue);
}

/* UPDATE BLOCK */

.title_dash {
    color: var(--dark-blue);
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    letter-spacing: -0.5px;
    flex-wrap: wrap;
    /* border-top: 1px solid var(--dark-blue); */
    padding: 0 0 2rem 0;
    display: flex;
    align-items: center;
}

.title_dash_red {
    background-color: var(--red);
    color: var(--white);
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: -0.5px;
    flex-wrap: wrap;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.title_dash_red svg {
    fill: var(--white);
    height: 2rem;
}

.title_dash.maxed {
    justify-content: space-between;
}

.update_title_gradient {
    font-size: 1.4rem;
    font-weight: 700;
    background: -webkit-linear-gradient(var(--gradient-blue), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

ul.latest_news_dashboard {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.latest_news_dashboard li {
    line-height: 1.4;
    border-bottom: 1px dotted var(--dim-grey);
    padding: 1rem 0;
}

ul.latest_news_dashboard .latest_news_task {
    color: var(--reg-text);
    font-size: 1.4rem;
    font-weight: 700;
}


ul.latest_news_dashboard .latest_news_description {
    color: var(--grey);
    font-size: 1.2rem;
}

ul.latest_news_dashboard .latest_news_status {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

ul.latest_news_dashboard .latest_news_status .latest_news_status_block {
    color: var(--reg-text);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin: 0 1rem;
}

ul.latest_news_dashboard .latest_news_status .latest_news_status_block svg {
    /* cursor: pointer; */
    margin-right: 0.5rem;
}

ul.latest_news_dashboard .latest_news_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2rem;
    border: 1px solid var(--light-grey);
    border-radius: 3px;
    /* width: 80px; */
    height: fit-content;
    padding: 3px;
    cursor: pointer;
    color: var(--grey);
}

ul.latest_news_dashboard .latest_news_button svg {
    fill: var(--light-grey);
    margin-right: 3px;
}

ul.latest_news_dashboard .latest_news_button:hover {
    color: var(--reg-text);
}

ul.latest_news_dashboard .latest_news_button:hover svg {
    fill: var(--green);
}

ul.latest_news_dashboard .latest_news_button.upvoted {
    border-color: var(--green);
    color: var(--reg-text);
}

ul.latest_news_dashboard .latest_news_button.upvoted svg {
    fill: var(--green);
}

ul li.latest_news_view_more a,
ul li.latest_news_view_more {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--light-grey);
    cursor: pointer;
}

ul li.latest_news_view_more a svg {
    height: 1.5rem;
    fill: var(--light-grey);
    margin-right: 0.5rem;
}

ul li.latest_news_view_more a:hover {
    color: var(--grey);
}

ul li.latest_news_view_more a:hover svg {
    fill: var(--grey);
}




/* KANBAN */

ul.kanban_dashboard {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.kanban_dashboard li {
    line-height: 1.4;
    border-bottom: 1px dotted var(--dim-grey);
    padding: 1rem 0;
}

ul.kanban_dashboard .kanban_task {
    color: var(--reg-text);
    font-size: 1.4rem;
    font-weight: 700;
}


ul.kanban_dashboard .kanban_description {
    color: var(--grey);
    font-size: 1.2rem;
}

ul.kanban_dashboard .kanban_title {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--grey);
}

ul.kanban_dashboard .kanban_status {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

ul.kanban_dashboard .kanban_status .kanban_status_block {
    color: var(--reg-text);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin: 0 1rem;
}

ul.kanban_dashboard .kanban_status .kanban_status_block svg {
    margin-right: 0.5rem;
}

ul.kanban_dashboard .kanban_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2rem;
    /* border: 1px solid var(--light-grey); */
    /* border-radius: 3px; */
    height: fit-content;
    /* padding: 0 3px; */
    cursor: pointer;
    color: var(--light-grey);
}

ul.kanban_dashboard .kanban_button svg {
    fill: var(--light-grey);
    margin-right: 3px;
}

ul.kanban_dashboard .kanban_button:hover {
    color: var(--reg-text);
}

ul.kanban_dashboard .kanban_button:hover svg {
    fill: var(--green);
}

ul.kanban_dashboard .kanban_button.upvoted {
    border-color: var(--green);
    color: var(--reg-text);
}

ul.kanban_dashboard .kanban_button.upvoted svg {
    fill: var(--green);
}

ul li.kanban_view_more a,
ul li.kanban_view_more {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--light-grey);
    cursor: pointer;
}

ul li.kanban_view_more a svg {
    height: 1.5rem;
    fill: var(--light-grey);
    margin-right: 0.5rem;
}

ul li.kanban_view_more a:hover {
    color: var(--grey);
}

ul li.kanban_view_more a:hover svg {
    fill: var(--grey);
}


/* ISSUES */

ul.dash_issues_list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

ul.dash_issues_list li {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.5rem 0;
}

ul.dash_issues_list li .issue_block {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--red);
}

ul.dash_issues_list li .issue_block svg {
    fill: var(--grey);
    height: 1.8rem;
    margin-right: 1rem;
}

ul.dash_issues_list li .issue_block img {
    height: 1.8rem;
    margin-right: 1rem;
}

ul.dash_issues_list li .action_description {
    color: var(--grey);
    font-weight: 500;
    font-size: 1.2rem;
    padding-left: 3rem;
}

.clear_link {
    font-weight: 600;
    color: var(--twitter-blue);
    font-size: 1.2rem;
    padding: 1rem 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.clear_link svg {
    height: 2rem;
    margin-right: 0.5rem;
    fill: var(--twitter-blue);
}

.action_link {
    font-weight: 600;
    color: var(--twitter-blue);
    font-size: 1.2rem;
    padding: 1rem 3rem;
    cursor: pointer;
}

.action_link::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23222C37'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.action_link:hover::after {
    transform: translateX(3px);
}


/* DRAFTS */

.draft_dashboard {
    cursor: pointer;
}

/* ACTIVITY */

.dashboard_activity_card {
    width: 280px;
    height: auto;
    /* background: rgb(255, 255, 255); */
    /* background: linear-gradient(22deg, rgba(255, 255, 255, 1) 30%, rgba(227, 228, 233, 1) 100%); */
    border: 1px solid var(--dim-grey);
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem 2rem;
    font-size: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.dashboard_activity_card svg {
    height: 3rem;
    fill: var(--reg-text);
}

.dashboard_activity_card .data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.dashboard_activity_card .data .main_data {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 4rem;
    font-weight: 400;
    color: var(--reg-text);
    width: 100%;
}

.dashboard_activity_card .data .main_data .increase {
    color: var(--green);
    font-weight: 400;
    font-size: 1.1rem;
    letter-spacing: -1px;
    background-color: var(--light-green);
    border-radius: 3px;
    padding: 0.2rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_activity_card .data .main_data .increase svg {
    height: 1.3rem;
    fill: var(--green);
    margin-right: 0.2rem;
}

.dashboard_activity_card .data .main_data .decrease {
    color: var(--red);
    font-weight: 400;
    font-size: 1.1rem;
    letter-spacing: -1px;
    background-color: var(--light-red);
    border-radius: 3px;
    padding: 0.2rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_activity_card .data .main_data .decrease svg {
    height: 1.3rem;
    fill: var(--red);
    margin-right: 0.2rem;
}

.dashboard_activity_card .data .data_sub {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: var(--grey);
    /* height: 50px; */
}

.dashboard_activity_card .data .data_sub svg {
    height: 2rem;
    margin: 0 1rem 0 0;
    fill: var(--grey);
}