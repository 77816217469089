.post_analytics {
    display: flex;
    flex-direction: column;
    padding: 0 1rem 0.5rem 1rem;
}

.post_analytics_data {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: var(--grey);
    font-weight: 700;
}

.column_analytics_account {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* max-height: 80vh; */
    overflow-y: auto;
    width: 250px;
    margin: 0 auto;
}