.loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  position: relative;
}

/* .loader-wrapper img {
  width: 100px;
  height: 100px;
} */

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle>div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  animation: lds-circle 4.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-circle>div img {
  width: 64px;
  height: 64px;
}

@keyframes lds-circle {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}


/* SIMPLE LOADER */

.spk-loader {
  position: absolute;
  top: 0px;
  left: 0px;
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid var(--twitter-blue);
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}




.loader {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.quick_loader_bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--grey);
  z-index: 1001;
  opacity: 0.5;
}

.quick_loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 1002;
  justify-content: center;
  align-items: center;
}

.lds-ring-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.lds-ring-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--blue);
  border-radius: 50%;
  animation: lds-ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--blue) transparent transparent transparent;
}

.lds-ring-loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-loader div:nth-child(3) {
  animation-delay: -0.15s;
}


.lds-ring-loader-small {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring-loader-small div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid var(--blue);
  border-radius: 50%;
  animation: lds-ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--blue) transparent transparent transparent;
}

.lds-ring-loader-small div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-loader-small div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-loader-small div:nth-child(3) {
  animation-delay: -0.15s;
}

.lds-ring-loader-mini {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds-ring-loader-mini div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid var(--blue);
  border-radius: 50%;
  animation: lds-ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--blue) transparent transparent transparent;
}

.lds-ring-loader-mini div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-loader-mini div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-loader-mini div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}