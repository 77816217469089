.contentai_logo_upload {
    width: 130px;
    height: 130px;
    border: 3px dashed var(--light-grey);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    text-align: center;
    color: var(--grey);
    font-weight: 500;
    cursor: pointer;
}

.contentai_logo_upload.dark_bg {
    background-color: var(--reg-text);
    color: var(--white);
}

.contentai_logo_upload svg {
    height: 50px;
    fill: var(--light-grey);
}

.contentai_logo_upload.dark_bg svg {
    height: 50px;
    fill: var(--white);
}

.contentai_logo_upload.active {
    border: 3px dashed var(--gradient-blue);
    color: var(--gradient-blue);
}

.contentai_logo_upload.active svg {
    fill: var(--gradient-blue);
}

.contentai_logo_img img {
    width: 110px;
    height: 110px;
    overflow: hidden;
    object-fit: contain;
    /* padding: 1rem; */
    /* border-radius: 5px; */
}

.contentai_logo_img_dark {
    background-color: var(--reg-text);
    width: 100px;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentai_logo_img_dark.selected {
    border: 3px solid var(--gradient-blue);
}

.contentai_logo_img img {
    width: 110px;
    height: 110px;
    object-fit: contain;
}

.contentai_logo_img_dark img {
    width: 70px;
    height: 70px;
    object-fit: contain;
}


input[type='text'].contentai_logo,
input[type="text"]:focus.contentai_logo,
input[type="text"]:active.contentai_logo {
    background-color: var(--dimmer-grey);
    /* border: 2px solid var(--white); */
    /* color: var(--white); */
    font-weight: 700;
}

input[type='text'].contentai_brand_description,
input[type="text"]:focus.contentai_brand_description,
input[type="text"]:active.contentai_brand_description {
    background-color: var(--dimmer-grey);
    color: var(--grey);
    font-weight: 400;
}

input[type='text'].contentai_logo.color_picker,
input[type="text"]:focus.contentai_logo.color_picker,
input[type="text"]:active.contentai_logo.color_picker {
    min-width: 10rem;
}

select.contentai_logo {
    background-color: var(--dimmer-grey);
    padding: 10px;
    /* border: 2px solid var(--white); */
    border-radius: 5px;
    /* color: var(--white); */
    font-weight: 700;
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: fit-content;
}

.primary_color_container {
    background-color: var(--white);
    padding: 1rem;
    border-radius: 5px;
    color: var(--reg-text);
    font-weight: 500;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 12rem;
    border: 1px solid var(--light-grey);
    margin: 0.5rem;
    text-transform: uppercase;
}

.primary_color_container.selected {
    border: 3px solid var(--gradient-blue);
}

.primary_color_square {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    border-radius: 2px;
    border: 1px solid var(--light-grey);
}

.color_picker_wrapper {
    position: absolute;
    /* padding: 2rem 1rem; */
    background-color: red;
    bottom: 0px;
    left: 20px;
    /* width: 500px; */
    /* height: 700px; */
    z-index: 10;
}

.brand_name {
    color: var(--white);
    font-size: 2rem;
    font-weight: 700;
}

.brand_description {
    color: var(--white);
    font-size: 2rem;
    font-weight: 400;
}

textarea.contentai_textarea {
    min-height: 10rem;
}

.content_ai_char_count {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--grey);
    font-size: 1rem;
}

.cai_icon_picker {
    position: absolute;
    /* height: 300px; */
    width: 200px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    top: 30px;
    left: 0px;
    z-index: 11000;
    padding: 1rem;
    background-color: var(--white);
    border-radius: 5px;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.cai_icon {
    padding: 0.5rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cai_icon svg {
    width: 2rem;
    height: 2rem;
    fill: var(--grey);
}

.cai_icon:hover svg {
    fill: var(--red);
}

.cai_icon img {
    width: 2rem;
    height: 2rem;
}

.cai_icon:hover img {
    background-color: var(--light-grey);
}

.unsplash_wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 400px;
    height: 400px;
    background-color: var(--white);
    padding: 1rem;
    border: 1px solid var(--grey);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.68);
}

.brands_table {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
}

.brands_table.active {
    height: 400px;
    overflow-y: auto;
}

.brands_container {
    display: flex;
    flex-wrap: wrap;
}

.add_new_brand {
    width: 120px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 500;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    margin: 1rem;
    padding: 0.5rem;
    transition: all 0.3s ease;
}

.add_new_brand:hover {
    transform: translate(2px, -2px);
}

.add_new_brand svg {
    height: 4rem;
    fill: var(--white);
}

.brand_picker {
    width: 120px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--reg-text);
    font-size: 1.2rem;
    overflow: hidden;
    font-weight: 500;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid var(--light-grey);
    text-align: center;
    margin: 1rem;
}

.brand_picker img {
    height: 4rem;
    width: 4rem;
    object-fit: contain;
}

.new_brand {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
}

.new_brand.active {
    height: 520px;
}


/* Library picker */

.content_ai_library_picker {
    position: fixed;
    top: 10rem;
    left: 30rem;
    padding: 2rem;
    width: 500px;
    background-color: var(--white);
    z-index: 10;
    border: 1px solid var(--dim-grey);
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.sn_fit_cai {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 4rem;
}

.sn_fit_cai img {
    height: 1.2rem;
    margin: 0 0.2rem;
    opacity: 0.8;
    /* filter: grayscale(100%) opacity(80%); */
}


.cai_preview_img {
    width: 600px;
    height: 600px;
}

.cai_preview_img img {
    width: 100%;
    height: auto;
}