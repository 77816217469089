/* GRID */

section.library {
    display: grid;
    min-height: 100vh;
    width: 100%;
    padding: 0;
    height: 100%;
    grid-template-columns: 1fr 400px;
    grid-template-rows: 1fr;
    grid-template-areas:
        "library-content library-menu"
}

.library-content {
    grid-area: library-content;
    position: relative;
}

.library-menu {
    grid-area: library-menu;
    margin: 0 4rem;
}

.library-menu-wrapper {
    /* position: fixed; */
    /* top: 10rem; */
    /* width: 300px; */
    display: flex;
    flex-direction: column;
    background-color: var(--dim-grey);
    padding: 2rem;
    border-radius: 5px;
}

.library-menu-wrapper .library-menu-help {
    display: flex;
    /* flex-direction: column; */
    /* align-items: flex-start; */
    align-items: center;
    color: var(--grey);
    font-size: 1rem;
    font-style: italic;
}

.library-menu-wrapper .library-menu-help svg {
    height: 2rem;
    fill: var(--grey);
    margin-right: 2rem;
}


.library-menu-wrapper .library-menu-block {
    display: flex;
    flex-direction: column;
}

.library-menu-wrapper .library-menu-block .title {
    font-size: 2rem;
    color: var(--reg-text);
    font-weight: 400;
    margin: 1rem 0;
}

.library-menu-wrapper .library-menu-block ul.library-menu-list {
    list-style: none;
    margin: 0 0 4rem 0;
    padding: 0;
}

.library-menu-wrapper .library-menu-block ul.library-menu-list li {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--light-grey);
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    color: var(--grey);
    cursor: pointer;
}

.library-menu-wrapper .library-menu-block ul.library-menu-list li:hover {
    color: var(--reg-text);
}

.library-menu-wrapper .library-menu-block ul.library-menu-list li.selected {
    -webkit-box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
    box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
    color: var(--reg-text);
    border: none;
}

.library-menu-wrapper .library-menu-block ul.library-menu-list li .profile_icon {
    margin-right: 2rem;
}

.library-menu-wrapper .library-menu-block ul.library-menu-list li .profile_icon.team_profile {
    font-weight: 500;
    font-size: 1rem;
}

.library-menu-wrapper .library-menu-block ul.library-menu-list li svg {
    height: 2rem;
    margin: 0 3rem 0 1rem;
    fill: var(--light-grey);
}

.library-menu-wrapper .library-menu-block ul.library-menu-list li.selected svg {
    fill: var(--grey);

}

/* FILTERS */

.filter_row {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    width: 100%;
    border-radius: 10px;
    padding: 1rem;
}

.filter_item {
    display: flex;
    align-items: center;
    margin: 0 2rem;
    cursor: pointer;
}

.filter_item p {
    color: var(--grey);
}

.filter_item svg {
    height: 2rem;
    fill: var(--light-grey);
}

.filter_item.selected p {
    color: var(--red);
}

.filter_item.selected svg {
    fill: var(--red);
}


/* .bulk-menu-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--dim-grey);
    padding: 2rem;
    border-radius: 5px;
    margin-top: 4rem;
}


ul.bulk_move_options {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

ul.bulk_move_options li {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: var(--grey);
    padding: 0.5rem 1rem;
}

ul.bulk_move_options li.root_folder {
    font-weight: 700;
} */

/* FOLDERS */



ul.folders-tree-content {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    /* background-color: red; */
}

/* ul.folders-tree-content.move-files li>* {
    color: var(--white) !important;
    fill: var(--white) !important;
} */

ul.folders-tree-content li {
    display: flex;
    flex-direction: column;
}

ul.folders-tree-content li.team-folder-data,
ul.folders-tree-content li .folder-data,
ul.subfolder-content li .folder-data {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 1.2rem;
    color: var(--grey);
    cursor: pointer;
}

ul.folders-tree-content li.team-folder-data,
ul.folders-tree-content.move-files li .folder-data,
ul.subfolder-content.move-files li .folder-data {
    color: var(--white);
}

ul.folders-tree-content li.team-selected-for-move,
ul.folders-tree-content.move-files li .folder-data.selected-for-move,
ul.subfolder-content.move-files li .folder-data.selected-for-move {
    color: var(--reg-text);
    background-color: var(--white);
}

ul.folders-tree-content li .folder-data svg,
ul.subfolder-content li .folder-data svg {
    fill: var(--grey);
    height: 2rem;
    margin: 0 1rem;
}

ul.folders-tree-content.move-files li .folder-data svg,
ul.subfolder-content.move-files li .folder-data svg {
    fill: var(--white);
}

ul.folders-tree-content.move-files li .folder-data.selected-for-move svg,
ul.subfolder-content.move-files li .folder-data.selected-for-move svg {
    fill: var(--reg-text);
}

.folder-data .folder-action {
    opacity: 0;
    display: flex;
    align-items: center;
}

.folder-data:hover .folder-action {
    opacity: 1;
}

ul.subfolder-content li .subfolder-action {
    opacity: 0;
    display: flex;
    align-items: center;
}

.move-files .folder-data .folder-action,
.move-files ul.subfolder-content li .subfolder-action,
.move-files ul.subfolder-content li ul.subfolder-content li .subfolder-action {
    display: none;
}

ul.subfolder-content li:hover .subfolder-action {
    opacity: 1;
}

ul.subfolder-content li ul.subfolder-content li .subfolder-action {
    opacity: 0;
    display: flex;
    align-items: center;
}


ul.subfolder-content li ul.subfolder-content li:hover .subfolder-action {
    opacity: 1;
}

.folder-action svg,
.subfolder-action svg {
    cursor: pointer;
    height: 1.8rem !important;
    margin: 0 0.5rem !important;
    opacity: 0.4;
}

.folder-action svg:hover,
.subfolder-action svg:hover {
    opacity: 1;
}


.folders-card ul.folders-tree-content li:hover,
.folders-card ul.folders-tree-content li.folder_focused {
    transform: scale(1.05);
    transition: all 0.1s ease-in-out;
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.23);
}



ul.subfolder-content {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0 0 0 2rem;
    padding: 0;
    /* background-color: red; */
}

ul.subfolder-content li {
    display: flex;
    flex-direction: column;
}


ul.subfolder-content li svg {
    fill: var(--grey);
    height: 2rem;
    margin: 0 1rem;
}

.new-folder {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 13;
    color: var(--white);
    padding: 2rem;
    border-radius: 10px;
}


input[type="text"].new_folder_input,
input[type="text"].new_folder_input:focus,
input[type="text"].new_folder_input:active {
    background-color: var(--white);
    padding: 5px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: 1.2rem;
    width: 5rem;
    color: var(--reg-text);
}

.bulk-move {
    position: fixed;
    top: 20%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 13;
    color: var(--white);
    padding: 2rem;
    border-radius: 10px;
}

.move_button {}

/* MEDIA */

.media_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 2rem;
}

.no_media {
    background-color: var(--white);
    width: 80rem;
    height: 60rem;
    padding: 2rem;
    border-radius: 3rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.no_media .no_media_title {
    color: var(--grey);
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
}

.no_media svg {
    height: 8rem;
    margin: 5rem;
    fill: var(--light-grey);
}

.no_media img {
    width: 100%;
    /* margin: 5rem; */
    /* fill: var(--light-grey); */
}


/* GRID VIEW */

.media_card_grid {
    position: relative;
    height: 150px;
    width: fit-content;
    margin: 0.8rem;
    background-color: var(--white);
    transition: all 0.3s ease;
    cursor: pointer;
    /* box-shadow: 0 4px 8px 0 rgba(199, 156, 156, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19); */
    /* overflow: hidden; */
    -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.4);
}

.media_card_grid:hover {
    transform: scale(1.03);
}

.media_card_grid img {
    /* max-width: 100%; */
    height: 100%;
    /* width: fit-content; */
}

.media_card_grid .media_video {
    /* max-width: 100%; */
    height: 150px;
    width: fit-content;
    position: relative;
}

.media_card_grid .media_video video {
    height: 150px;
    /* width: 100%; */
    object-fit: contain;
}

.media_card_grid .media_video .video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .60);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media_card_grid .media_video .video-overlay svg {
    height: 4rem;
    width: 4rem;
    fill: var(--white);
    cursor: pointer;
}

.media_card_grid .clipped {
    height: 2.8rem;
    width: 2.8rem;
    /* background-color: var(--red); */
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 11;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateZ(-30deg);
}

.media_card_grid .clipped svg {
    height: 2.8rem;
    width: 2.8rem;
    fill: var(--red);
}


.bulk_move_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .60);
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bulk_move_mask .bulk_move_selection {
    height: 3rem;
    width: 3rem;
    border: 1px solid var(--white);
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bulk_move_mask .bulk_move_selection svg {
    height: 3rem;
    fill: var(--white);
}

.bulk_move_mask .bulk_move_selection svg.media_selected {
    display: block;
}

.bulk_move_mask .bulk_move_selection svg.media_not_selected {
    display: none;
}


/* SELECTION */

.selection_circle_background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9;
}

.selection_circle {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 1px solid var(--grey);
    background-color: var(--white);
    overflow: hidden;
    z-index: 10;
}

.selection_circle.selected {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 2px solid var(--bright-green);
    background-color: var(--bright-green);
    overflow: hidden;
    z-index: 10;
}

.selection_circle svg {
    fill: var(--white);
}

/* Action overlay */

.overlay-background {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    filter: blur(10rem);
    z-index: 12;
}

.action-overlay {
    position: fixed;
    bottom: -100px;
    left: 50%;
    margin-left: -350px;
    width: 700px;
    height: 80px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 13;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.action-overlay.displayed {
    transform: translateY(-90px);
}

.action-overlay .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0 2rem;
}

.action-overlay .item svg {
    fill: var(--white);
    height: 2rem;
}

.move-overlay {
    position: fixed;
    top: 10%;
    left: 50%;
    margin-left: -30%;
    width: 60%;
    /* height: 400px; */
    height: fit-content;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 13;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
    /* justify-content: space-around; */
    /* align-items: center; */
}

.overlay-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    width: 100%;
}

.overlay-row {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.overlay-items {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    text-align: center;
    width: 10rem;
    padding: 2rem 0;
    border-radius: 10px;
}

.overlay-items:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.overlay-items.selected {
    background-color: rgba(255, 255, 255, 0.4);
}

.overlay-items svg {
    height: 3rem;
    fill: var(--white);
}