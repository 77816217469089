.pricing_grid {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.pricing_grid_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 2rem;
    border-radius: 10px;
}

.pricing_grid_row.grey {
    background-color: var(--dimmer-grey);
}

.pricing_grid_row.options {
    /* border: 3px solid var(--gradient-blue); */
    padding: 1rem;
    justify-content: space-around;
    background-color: var(--dimmer-grey);
}

.pricing_grid_row .billing_options {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing_grid_row .option_slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.pricing_grid_row .option_slider .slider {
    width: 300px;
    display: flex;
    align-items: center;
}

.pricing_grid_row.highlighted {
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 500;
    font-size: 2.3rem;
    padding: 0.5rem;
}

.pricing_grid_description_block {
    width: 300px;
}

.pricing_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid var(--light-grey); */
    /* width: 300px; */
    border-radius: 10px;
    padding: 1rem 0;
    position: relative;
    width: 180px;
    background-color: var(--dimmer-grey);
    margin: 0 2rem;
}

.pricing_header.special {
    background-image: linear-gradient(to top, #df0022, #fd4a65);
    color: var(--white)
}

.pricing_header.recommended {
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    /* background-image: linear-gradient(to top, #26a500, #85ff5f); */
    color: var(--white);
}

.pricing_header .reco_mention {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -75px;
    background-color: var(--red);
    color: var(--white);
    font-weight: 700;
    font-size: 1.1rem;
    padding: 4px;
    line-height: 1;
    text-align: center;
    height: 20px;
    width: 150px;
    border-radius: 10px;
}

.pricing_header .x_mas_logo {
    position: absolute;
    top: -2rem;
    right: -3rem;
    transform: rotateZ(10deg);
}

.pricing_header .x_mas_logo img {
    height: 5rem;
    width: 100%;
}

.pricing_header .pricing_header_limited {
    position: absolute;
    top: -20px;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: var(--red);
}

.pricing_header .pricing_header_plan_name {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}

.pricing_header .pricing_header_price {
    display: flex;
    align-items: flex-start;
    font-size: 4rem;
    line-height: 4rem;
}

.pricing_header .pricing_header_price .currency {
    font-size: 3rem;
}

.pricing_grid_data_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
}

.pricing_grid_data_header_slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
    border: 1px solid var(--twitter-blue);
    padding: 0.5rem;
    border-radius: 5px;
}

.pricing_grid_data_header_slider .slider {
    display: flex;
    align-items: center;
}

.pricing_grid_data {
    display: flex;
    justify-content: center;
    margin: 0 1rem;
}

.pricing_grid_data.main {
    font-size: 1.5rem;
    font-weight: 600;
}

.pricing_grid_data svg {
    fill: var(--green);
    height: 2rem;
}

.pricing_grid_data.red svg {
    fill: var(--red);
    height: 2rem;
}

.discount {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    /* background-color: var(--blue); */
    color: var(--white);
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    margin-left: 1rem;
    white-space: nowrap;
}