.table_search_bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  width: 100%;
}

.table_add_item svg {
  fill: var(--green);
  height: 3rem;
  margin-right: 2rem;
  cursor: pointer;
}


/* REACT DATA TABLE */

.rdt_TableHeader,
.rdt_TableHead {
  background-color: var(--white) !important;
}

.rdt_Table,
.rdt_TableRow,
.rdt_TableHeadRow,
.rdt_TableCol,
.rdt_Pagination,
.rdt_ExpanderRow {
  background-color: transparent !important;
  color: var(--main-color) !important;
}

.rdt_Table {
  max-width: 80vw !important;
}

.rdt_TableRow button svg {
  fill: var(--blue);
}

.rdt_TableCell {
  padding: 0 !important;
}

/* .rdt_TableCol {
  min-width: 50px !important;
} */

.rdt_TableCol_Sortable {
  color: var(--main-color);
  font-weight: 700 !important;
}

.rdt_TableCol_Sortable:hover {
  color: var(--blue) !important;
}

.expandedRow {
  background-color: var(--input-background);
  padding: 2rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.expandedRowAction {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.expandedRowAction svg {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.5rem;
}

.accounts_list {
  display: flex;
  flex-wrap: wrap;
}

.cell_svg {
  display: flex;
  align-items: center;
}

.cell_svg svg {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  margin-left: 1rem;
}