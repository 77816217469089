.team-dropdown-main {
    width: 100%;
    height: fit-content;
    background-color: var(--dimmer-grey);
    cursor: pointer;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 1px solid var(--light-grey);
}

.team-dropdown-main svg {
    height: 2rem;
    fill: var(--grey);
}

.team-dropdown-profile {
    display: flex;
    align-items: center;
}

ul.team-dropdown-list {
    list-style: none;
    position: absolute;
    top: 60px;
    left: 0px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: none;
    border: 1px solid var(--grey);
}

ul.team-dropdown-list.displayed {
    display: block;
}

ul.team-dropdown-list li {
    background-color: var(--dimmer-grey);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem;
    padding: 0.5rem 2rem;
    border-bottom: 1px dotted var(--light-grey);
}

ul.team-dropdown-list li:hover,
ul.team-dropdown-list li.selected {
    background-color: var(--dim-grey);
}

ul.team-dropdown-list li.selected {
    cursor: default;
}

ul.team-dropdown-list li:last-child {
    border-bottom: none;
}