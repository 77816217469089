.signin_container {
    background-color: transparent;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 4rem;
    flex-wrap: wrap;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
}

.particles {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    /* background: linear-gradient(-45deg, #1606ce, #3D2BFF); */
    background-size: 100% 100%;
    z-index: -1;
}

.signup_block {
    padding: 0 2rem;
    line-height: 1.5;
    max-width: 30rem;
}

.signup_block .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.signup_block .logo svg {
    fill: var(--white);
    height: 18rem;
    width: 18rem;
    margin-bottom: 1rem;
}

.signup_block .title,
.signup_block h1 {
    font-size: 2.2rem;
    font-weight: 400;
    color: var(--white);
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 5rem;
    text-transform: uppercase;
}


.signup_block_adv {
    padding: 0 2rem;
    line-height: 1.5;
    max-width: 60rem;
}

.signup_block_adv .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 2rem;
    font-weight: 400;
    /* margin-bottom: 2rem; */
}

.signup_block_adv .logo svg {
    fill: var(--white);
    height: 14rem;
    width: 14rem;
    /* margin-bottom: 1rem; */
}

.signin_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    padding-bottom: 8rem;

}

.signin_content .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.signin_content .logo svg {
    fill: var(--white);
    height: 18rem;
    width: 18rem;
    margin-bottom: 1rem;

}

.signin_form_link a,
.signin_form_link a.visited,
.signin_form_link.link {
    color: var(--reg-text);
    text-decoration: none;
    margin-left: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
}

.signin_link {
    display: flex;
    font-size: 1.2rem;
}

.signin_link a,
.signin_link a.visited {
    color: var(--white);
    text-decoration: underline;
    margin-left: 1rem;
}

.signing_form_wrapper {
    /* background-color: var(--background-color); */
    background-color: var(--white);
    padding: 3rem;
    margin-bottom: 3rem;
    border-radius: 3rem;
    min-width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    color: var(--reg-text);
}

.signing_form_wrapper .logo_title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signing_form_wrapper .logo_title svg {
    height: 8rem;
    margin: 0 1rem;
}

.signing_form_wrapper .logo_title svg.arrows {
    height: 4rem;
    margin: 0 1rem;
    fill: var(--grey);
}

.signing_form_wrapper .title,
.signing_form_wrapper h1 {
    font-size: 2.2rem;
    font-weight: 500;
    color: var(--reg-text);
    letter-spacing: -1px;
    text-align: center;
    text-transform: uppercase;
}

form.signing_form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 3rem 1rem;
    gap: 0 !important;
    padding: 0 2rem;
}


form.signing_form .form_row {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0 !important;
}

form.signing_form .form_row label {
    color: var(--reg-text);
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

form.signing_form .form_row label sup {
    color: var(--red);
    font-size: 1.2rem;
}

form.signing_form .form_row input.signin_input {
    width: 100% !important;
    background-color: transparent;
    border-radius: 0;
    padding: 1rem;
    /* border: 1px solid var(--blue); */
    border-bottom: 1px solid var(--dim-grey);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);

}

form.signing_form button {
    cursor: pointer;
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    /* background: linear-gradient(45deg, #1606ce, #3D2BFF); */
    color: var(--white);
    font-size: 2rem;
    font-weight: 400;
    padding: 1.4rem;
    outline: none;
    border-radius: 1rem;
    border: none;
    margin-top: 1rem;
}

form.signing_form button:hover {
    filter: brightness(150%);
}

.no_account {
    background-color: var(--dim-grey);
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    color: var(--red);
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem;
    width: 100%;
}

/* LOADER */

.lds-ring {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    margin: 0px auto;
    border: 1px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



/* CAROUSEL */

.signup_carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signup_carousel img {
    width: 100%;
}

.signup_carousel h1 {
    font-size: 2.2rem;
    font-weight: 500;
    color: var(--white);
    letter-spacing: -1px;
    text-align: center;
    margin: 0;
    padding: 0;
}

.signup_carousel .testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--white);
    margin-top: 3rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    /* border: none; */
    border-radius: 10px;
    /* height: 356px; */
    border: 1px solid #ddd;
    margin-bottom: 6rem;
    /* box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07); */
}

.signup_carousel .testimonials img {
    margin: 4rem 0 0 0;
    width: 80px !important;
    border-radius: 50%;
    border: 4px solid transparent !important;
    background:
        linear-gradient(#222C37, #222C37) padding-box,
        linear-gradient(to right, #3D2BFF, #19A4FE) border-box;
}

.signup_carousel .testimonials .stars {
    display: flex;
    justify-content: center;
    margin: 0 0 2rem 0;
}

.signup_carousel .testimonials .stars svg {
    width: 2rem;
    fill: var(--blue);
}

.signup_carousel .testimonials h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin: 1rem auto 0 auto;
}

.signup_carousel .testimonials h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: var(--grey);
    /* color: #787878; */
    font-size: 1.2rem;
}

.signup_carousel .testimonials p {
    line-height: 29px;
    font-weight: 500;
    /* color: #222; */
    color: var(--reg-text);
    font-size: 1.6rem;
    margin-top: 3rem;
    position: relative;
}


.signup_carousel .testimonials p:before {
    /* content: "“";
      color: #aaa;
      font-size: 26px;
      font-family: monospace;
      font-weight: 100; */
    font-family: Georgia, serif;
    content: '\201C';
    color: var(--grey);
    font-size: 7.5em;
    font-weight: 700;
    opacity: .3;
    position: absolute;
    top: 2rem;
    left: -.2em;
    text-shadow: none;
    z-index: 0;
}

.signup_carousel .testimonials p:after {
    content: "”";
    /* color: #aaa; */
    color: var(--grey);
    font-size: 26px;
    font-family: Georgia, serif;
    /* font-family: monospace; */
    font-weight: 100;
    line-height: 0;
}

.signup_carousel .testimonials .feature_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}

.signup_carousel .testimonials .feature_block svg {
    height: 3rem;
    fill: var(--blue);
}

.signup_carousel .testimonials .feature_block .feature_desc {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    font-weight: 500;
    /* text-transform: uppercase; */
    font-size: 1.4rem;
    margin: 1rem auto 0 auto;
}