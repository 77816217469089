@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.content_ai_preview {
    background-color: var(--white);
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.68);
    margin: 1rem;
}

.content_ai_preview.square {
    width: 400px;
    height: 400px;
}

.content_ai_preview.portrait {
    width: 400px;
    height: 500px;
}

.content_ai_preview.landscape {
    width: 600px;
    height: 314px;
}

.content_ai_preview.portrait_pn {
    width: 400px;
    height: 555px;
}

.content_ai_preview.landscape_gmb {
    width: 600px;
    height: 450px;
}

.content_ai_preview .svg_bg {
    width: 100%;
    height: 100%;
    margin: auto;
}

.content_ai_preview .svg_bg svg {
    width: 300px;
    height: 300px;
    fill: var(--dim-grey);
}

.content_ai_preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* mariko template */

.content_ai_preview .mariko_logo {
    position: absolute;
    width: 55px;
    height: fit-content;
    bottom: 3px;
    left: 5px;
    z-index: 3;
}

.content_ai_preview .mariko_logo img {
    width: 100%;
    height: auto;
}

.content_ai_preview .mariko_band {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 50%;
    /* height: 400px; */
}


.content_ai_preview .mariko_block_text {
    position: absolute;
    width: 200px;
    height: fit-content;
    top: 40px;
    right: 0px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.content_ai_preview .mariko_block_text .headline {
    color: #FFF;
    font-weight: 700;
    font-family: 'Roboto';
    /* font-style: italic; */
    /* text-transform: uppercase; */
    font-size: 2.6rem;
    text-align: right;
    padding: 0 1rem;
}

.content_ai_preview .mariko_block_text .text {
    color: #FFF;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 2rem;
    text-align: right;
    font-size: 1.5rem;
    padding: 1rem;
}

.content_ai_preview .mariko_block_text .cta {
    color: #FFF;
    font-weight: 700;
    font-family: 'Roboto';
    margin-top: 2rem;
    text-align: center;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    width: 180px;
    /* border-radius: 10px; */
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-wrap: nowrap; */
    white-space: nowrap;
}

.content_ai_preview .mariko_block_text .cta svg {
    margin-left: 0.5rem;
    height: 2rem;
    fill: var(--white);
}


.composer_component_previews {
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.composer_component_previews::-webkit-scrollbar {
    display: none;
}

/* LOADER TEMPLATES */

.content_ai_loading_view .templates_loader {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 2rem 0;
}

.content_ai_loading_view ul {
    list-style: none;
    margin: 0;
}

.content_ai_loading_view ul li {
    display: flex;
    align-items: center;
    color: var(--grey);
}

.content_ai_loading_view ul li.done {
    color: var(--reg-text);
}

.content_ai_loading_view ul li svg {
    height: 3rem;
    fill: var(--light-grey);
    margin-right: 1rem;
}

.content_ai_loading_view ul li.done svg {
    fill: var(--bright-green);
}

.template_container {
    position: relative;
    width: 300px;
    /* height: 450px; */
    height: fit-content;
    /* background-color: red; */
    margin: 1rem;
    padding: 0;
    /* -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.68); */
    transition: all 0.3s ease;
    cursor: pointer;
}

.template_container_landscape {
    position: relative;
    width: 450px;
    margin: 1rem;
    padding: 0;
    /* -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.68); */
    transition: all 0.3s ease;
    cursor: pointer;
}

.selected_template_container {
    position: relative;
    width: 300px;
    margin: 1rem;
    padding: 0;
    background-color: var(--white);
    border: 1px solid #d0d1d5;
    box-shadow: 0 0 5px rgba(0, 0, 0, .30);
    transition: all 0.3s ease;
}

.selected_template_container_landscape {
    position: relative;
    width: 450px;
    margin: 1rem;
    padding: 0;
    background-color: var(--white);
    border: 1px solid #d0d1d5;
    box-shadow: 0 0 5px rgba(0, 0, 0, .30);
    transition: all 0.3s ease;
}

.selected_template_container .delete_template,
.selected_template_container_landscape .delete_template {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.selected_template_container .delete_template svg,
.selected_template_container_landscape .delete_template svg {
    height: 2rem;
    width: 2rem;
    fill: var(--red);
}

.template_container:hover,
.template_container_landscape:hover {
    transform: scale(1.03);
}

.template_container img,
.template_container_landscape img,
.selected_template_container img,
.selected_template_container_landscape {
    width: 100%;
    margin: 0;
    padding: 0;
}

/* SELECTION */

.template_container .selection_circle_background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9;
}

.template_container .selection_circle {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 1px solid var(--grey);
    background-color: var(--white);
    overflow: hidden;
    z-index: 10;
}

.template_container .selection_circle.selected {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border: 2px solid var(--bright-green);
    background-color: var(--bright-green);
    overflow: hidden;
    z-index: 10;
}

.template_container .selection_circle svg {
    fill: var(--white);
}

.cai_icon_selection {
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-background);
}

.cai_icon_selection svg {
    width: 2.7rem;
    fill: var(--grey);
}

.cai_icon_selection img {
    width: 2rem;
    height: 2rem;
}

.content_ai_caption {
    margin: 1rem;
    font-size: 1.2rem;
}

.content_ai_caption .ig_tags {
    color: #00376b;
}



/*
.content_ai_preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content_ai_preview .gradient {
    width: 400px;
    height: 400px;
    background-image: linear-gradient(to right, transparent, transparent, white);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.content_ai_preview .logo {
    position: absolute;
    width: 55px;
    height: fit-content;
    bottom: 3px;
    right: 5px;
    z-index: 3;
}

.content_ai_preview .logo img {
    width: 100%;
}

.content_ai_preview .band {
    position: absolute;
    width: 86px;
    height: 100%;
    top: 0px;
    left: 205px;
    opacity: 0.5;
    z-index: 2;
}

.content_ai_preview .block_text {
    position: absolute;
    width: 200px;
    height: fit-content;
    top: 40px;
    left: 148px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
}

.content_ai_preview .block_text .headline {
    color: #FFF;
    font-weight: 700;
    font-family: 'Roboto';
    font-style: italic;
    text-transform: uppercase;
    font-size: 1.8rem;
    text-align: center;
}

.content_ai_preview .block_text .text {
    color: #FFF;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 2rem;
    text-align: center;
    font-size: 1.5rem;
}

.content_ai_preview .block_text .cta {
    color: #000;
    font-weight: 700;
    font-family: 'Roboto';
    margin-top: 2rem;
    text-align: center;
    font-size: 1.4rem;
    background-color: #FFF;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    margin-top: 2rem;
} */