.billing_options_row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 8rem;
}

.billing_options_row .onoffswitch {
    margin: 0 2rem;
}


.card-pricing {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem;
    border-radius: 5px;
    margin: 2rem 2rem;
    width: 40rem;
    height: 100%;
    color: var(--main-color);
    -webkit-box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.49);
    box-shadow: 5px 5px 21px -2px rgba(0, 0, 0, 0.49);
}

.card-pricing-blue {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem;
    border-radius: 5px;
    margin: 2rem 2rem;
    width: 40rem;
    height: 100%;
    color: var(--main-color);
    -webkit-box-shadow: 5px 5px 21px -2px rgba(61, 43, 255, 0.49);
    box-shadow: 5px 5px 21px -2px rgba(61, 43, 255, 0.49);
}

.pricing_action {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
    cursor: pointer;
}

.accounts_needed {
    font-size: 6rem;
    font-weight: 300;
    width: 150px;
    text-align: center;
}

.pricing-arrow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* margin-top: 2rem; */
}

.pricing-arrow img {
    width: 200px;
}

.selected_plan {
    position: absolute;
    top: -1rem;
    left: 50%;
    width: 15rem;
    transform: translate(-50%);
    background-color: var(--red);
    color: var(--white);
    font-size: 1rem;
    font-weight: 700;
    height: 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.discount {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    background-color: var(--blue);
    color: var(--white);
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    margin: 0.5rem 0;
    /* margin-left: 1rem; */
}

.plan_name {
    font-size: 2rem;
    color: var(--blue);
    letter-spacing: -1px;
    font-weight: 700;
    margin-bottom: 2rem;
}

.plan_price {
    font-size: 3.5rem;
    font-weight: 700;
}

.plan_frequency {
    font-size: 1.4rem;
    color: var(--grey);
    margin-left: 1rem;
}

.sad-pika {
    display: flex;
    justify-content: center;
}

.sad-pika svg {
    height: 15rem;
}

.currency_button {
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    border: 1px solid var(--light-grey);
    background-color: var(--dim-grey);
    color: var(--grey);
    cursor: pointer;
}

.currency_button:hover {
    color: var(--red);
}

.currency_button.active {
    -webkit-box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
    box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
    color: var(--red);
    border: none;
}

ul.invoice_list li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--dim-grey);
    line-height: 3;
    cursor: pointer;
}