.pn_container_preview {
    display: flex;
    flex-direction: column;
    width: 251px;
    margin: 1rem 0 2rem 0;
    padding-bottom: 2rem;
    position: relative;
    border-bottom: 1px dotted var(--grey);
}

.pn_container {
    display: flex;
    width: 502px;
    padding: 12px;
    margin: 1rem 0;
    position: relative;
    background-color: white;
}

.pn_container.preview_card_draft {
    background: repeating-linear-gradient(45deg,
            var(--dim-grey),
            var(--dim-grey) 10px,
            var(--dimmer-grey) 10px,
            var(--dimmer-grey) 20px);
}

.pn_media {
    flex: 1
}

.pn_media_preview {
    position: relative;
}

.pn_plus_sign {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.pn_media_preview img {
    object-fit: contain;
    margin-right: 1rem;
    border-radius: 1rem;
    width: 251px;
    max-height: 502px;
}

.pn_media img {
    object-fit: contain;
    margin-right: 1rem;
    border-radius: 1rem;
    max-width: 250px;
}

.pn_media .video-pn-container {
    position: relative;
    overflow: hidden;
    margin-right: 0.5rem;
    border-radius: 1rem;
    max-width: 250px;
}

.pn_media .video-pn-container video {
    max-width: 250px;
}

.pn_media .video-pn-container .video_icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 8rem;
    width: 100%;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    padding: 1rem;
}


.pn_media .video-pn-container .video_icon svg {
    height: 3rem;
    width: 3rem;
    fill: var(--white);
}

.pn_content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.pn_header img {
    width: 100%;
    object-fit: contain;
    max-width: 250px;
}

.pn_link {
    color: #000;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
    text-decoration: underline;
    text-overflow: clip;
}

.pn_title {
    color: #000;
    font-size: 2.3rem;
    font-weight: 700;
    margin-top: 1rem;
    word-wrap: break-word;
    text-overflow: clip;
}

.pn_title_preview {
    color: #000;
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 0.5rem;
    word-wrap: break-word;
    text-overflow: clip;
    width: 100%;
    padding: 0 1rem;
}

.pn_description {
    color: var(--reg-text);
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 2rem;
    word-wrap: break-word;
    text-overflow: clip;
}

.pn_account {
    margin-top: 3rem;
    display: flex;
}

.pn_account .pn_icon {
    width: 4rem;
    margin-right: 1rem;
    border-radius: 2rem;
}

.pn_account .pn_nickname_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.pn_nickname {
    font-size: 1.4rem;
    color: rgba(var(--f75, 38, 38, 38), 1);
    font-weight: 700;
}

.pn_followers,
.pn_time {
    font-size: 1.3rem;
    color: rgba(var(--f75, 38, 38, 38), 1);
    font-weight: 500;
}


select.boards_select {
    margin-left: 1rem;
    padding: 0;
    height: min-content;
}