.feed-nav {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-weight: 600;
}

.feed-nav svg {
    height: 2rem;
    fill: var(--reg-text);
    cursor: pointer;
    margin: 0 1rem;
}


.account_header {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    position: relative;
}

.account_header .acc_name {
    font-weight: 700;
}

.feed-grid-view {
    width: 470px;
    display: flex;
    flex-wrap: wrap;
}

.feed-pub-date {
    font-weight: 700;
    font-size: 1rem;
    color: var(--blue);
    margin-bottom: 0.5rem;
}

/* GRID VIEW MEDIA STD */

.grid-view-caption {
    width: 150px;
    height: 150px;
    margin: 2px;
    position: relative;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: default;
    overflow-y: auto;
    overflow-x: hidden;
}

.grid-view-media {
    width: 150px;
    height: 150px;
    margin: 2px;
    position: relative;
}

.grid-view-media img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.grid-view-media .grid-view-video-container {
    width: 150px;
    height: 150px;
    position: relative;
}

.grid-view-media .grid-view-video-container video {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.grid-view-media .grid-view-video-container .video_overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    height: 150px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-view-media .grid-view-video-container .video_overlay svg {
    fill: var(--white);
    height: 4rem;
}

.grid-view-media .hover_caption {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 3;
    opacity: 0;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    /* cursor: default; */
    overflow: auto;
}

.grid-view-media .hover_caption .clickabke {
    cursor: pointer !important;
}

.grid-view-media:hover .hover_caption {
    opacity: 1;
}

.grid-view-media .upcoming {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    height: 150px;
    background-color: rgba(192, 192, 192, 0.8);
    z-index: 2;
}

.grid-view-media .failed {
    position: absolute;
    top: 0px;
    right: 5px;
    width: 10px;
    height: 10px;
    z-index: 2;
}

.grid-view-media .failed .failed_dot {
    height: 10px;
    width: 10px;
    fill: var(--red);
    margin-left: 2px;
}


/* GRID VIEW MEDIA PINTEREST */

.grid-view-caption-pn {
    width: 150px;
    height: 225px;
    margin: 2px;
    position: relative;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: default;
    overflow-y: auto;
    overflow-x: hidden;
}

.grid-view-media-pn {
    width: 150px;
    height: 225px;
    margin: 2px;
    position: relative;
}

.grid-view-media-pn img {
    width: 150px;
    height: 225px;
    object-fit: cover;
}

.grid-view-media-pn .grid-view-video-container {
    width: 150px;
    height: 225px;
    position: relative;
}

.grid-view-media-pn .grid-view-video-container video {
    width: 150px;
    height: 225px;
    object-fit: cover;
}

.grid-view-media-pn .grid-view-video-container .video_overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    height: 225px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-view-media-pn .grid-view-video-container .video_overlay svg {
    fill: var(--white);
    height: 4rem;
}

.grid-view-media-pn .hover_caption {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    height: 225px;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 3;
    opacity: 0;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: default;
    overflow: auto;
}

.grid-view-media-pn:hover .hover_caption {
    opacity: 1;
}

.grid-view-media-pn .upcoming {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 150px;
    height: 225px;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2;
}