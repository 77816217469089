.generic-card {
  background-color: white;
  /* height:400px; */
  width: 502px;
  border: 1px solid #d0d1d5;
  border-radius: 3px;
  /* margin: auto; */
  /* margin-bottom: 10px; */
  margin: 1rem;
  padding: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .30);
  /* Not original, just a test */
  position: relative;
}

.header {
  margin-bottom: 17px;
}


.co-logo-mockup {
  float: left;
  margin-right: 8px;
  width: 40px;
  height: 40px;
}

.co-name-mockup {
  display: block;
  width: 100px;
  height: 15px;
  background-color: var(--dim-grey);
  margin-bottom: 2px;
  margin-left: 48px;
}

.time-mockup {
  display: block;
  width: 50px;
  height: 12px;
  background-color: var(--dim-grey);
  margin-left: 48px;
}

.content {
  clear: both;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.38;
}

.content .text-mockup {
  width: 100%;
  height: 100px;
  /* background: linear-gradient(90deg, rgba(207,207,207,1) 0%, rgba(213,213,213,1) 35%, rgba(255,255,255,1) 100%); */
  background-color: var(--dim-grey);
}

.content .text {
  white-space: pre-wrap;
}

.content .tags {
  color: hsla(214, 89%, 52%, 1);
}

.reference {
  width: 476px;
}

.reference-media {
  width: 476px;
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-shrink: 1;
}

.reference-thumb {
  display: block;
  width: 476px;
  height: 249px;
}

.reference-thumb-photo {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  flex-shrink: 1;
}

.reference-thumb-video {
  display: block;
  max-width: 476px;
}

.video-container {
  position: relative;
}

.video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .60);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-overlay svg {
  height: 8rem;
  width: 8rem;
  fill: var(--white);
  cursor: pointer;
}

.reference-content {
  border: 2px solid black;
  border: 2px solid rgba(0, 0, 0, .1);
  border-top: 0;
  padding: 10px 12px 10px 12px;
}

.reference:hover .reference-content {
  border-color: black;
  /* Fallback */
  border-color: rgba(0, 0, 0, .15);
}

.reference-title {
  font-family: Helvetica;
  color: #050505;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  -webkit-font-smoothing: antialiased;
}

.reference-subtitle {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 16px;
  color: #65676b;
}

.reference-font {
  color: #90949c;
  font-family: Helvetica;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;

  padding-top: 9px;
}

.social {
  margin-top: 12px;
}

.social-buttons {
  color: #7f7f7f;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  border-top: 1px solid #e5e5e5;
  padding-top: 4px;

}

.social-buttons span {
  font-size: 12px;
  margin-right: 20px;
  padding: 4px 4px 4px 0;
}

.social-buttons span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.social-buttons span i {
  padding-right: 4px;
}

/* MEDIA */
.fb-media-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  max-width: 500px;
  /* max-height: 500px; */
  overflow: hidden;
  flex-wrap: wrap;
}

.fb-media-wrapper {
  flex: 1;
  min-width: 50%;
  min-height: 50%;
  position: relative;
}

.fb-media-wrapper img,
.fb-media-wrapper video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding: 2px;
}

/* PN GENERIC CARD */

.generic-card-pn {
  background-color: white;
  /* height:400px; */
  width: 520px;
  /* width: 100%; */
  /* border: 1px solid #d0d1d5; */
  /* border-radius:3px; */
  margin: 1rem;
  /* margin-bottom: 10px; */
  padding: 12px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, .30);  */
  position: relative;
  display: flex;
}

.pn-image-mockup {
  flex: 1;
  width: 250px;
  height: 250px;
  margin-right: 1rem;
  border-radius: 1rem;
  background-color: var(--dim-grey);
}

.pn-media {
  flex: 1;
  max-width: 250px;
}

.pn-media img {
  object-fit: contain;
  margin-right: 1rem;
  border-radius: 1rem;
  max-width: 240px;
}

.pn-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 250px;
}

.pn_icons {
  width: 100%;
}

.pn_title_mockup {
  width: 250px;
  height: 50px;
  background-color: var(--dim-grey);
  margin: 1rem 0;
}

.pn_desc_mockup {
  width: 250px;
  height: 100px;
  background-color: var(--dim-grey);
}

.pn-header {
  margin-bottom: 17px;
  margin: 1rem 0;
}




/* SKELETON */

.skeleton-box {
  /* display: inline-block; */
  /* height: 1em; */
  position: relative;
  overflow: hidden;
  /* background-color: #DDDBDD; */
  background-color: var(--dim-grey);
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}