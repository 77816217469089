.onboarding_radio {
    /* font-family: system-ui, sans-serif; */
    font-size: 3rem;
    font-weight: 400;
    line-height: 2;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    color: var(--white);
}

.onboarding_radio:focus-within {
    color: var(--white);
}

.onboarding_radio input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    color: var(--white);
    width: 3rem;
    height: 3rem;
    border: 1px solid var(--white);
    /* border-radius: 50%; */
    /* transform: translateY(-0.075em); */
    cursor: pointer;
    display: grid;
    place-content: center;
}

.onboarding_radio input[type="radio"]::before {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    /* box-shadow: inset 1rem 1rem var(--white); */
    /* Windows High Contrast Mode */
    background-color: var(--white);
    /* background-color: CanvasText; */
}

.onboarding_radio input[type="radio"]:checked::before {
    transform: scale(1);
}

.onbording_currency_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    font-size: 1.2rem;
    font-weight: 700;
    border: 1px solid var(--light-grey);
    background-image: linear-gradient(to top, #7e7e7e, #e8e8e8);
    color: var(--reg-text);
    margin: 0 1px;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.onbording_currency_button:hover,
.onbording_currency_button.selected {
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    border: 1px solid var(--white);
    color: var(--white);
}