.calendar .cal-body-simple {
  display: flex;
  flex-wrap: nowrap;
}


.cal-col-weekly-simple {
  flex: 1;
  min-height: 10rem;
  background: #eee;
  border-right: 1px solid var(--dim-grey);
  position: relative;
}

.calendar .cal-body-simple .cal-col-weekly-simple {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}


.cal-event-simple {
  cursor: pointer;
  display: flex;
  margin: 1rem 0.5rem;
  background-color: var(--white);
  padding: 0.2rem 0.5rem;
  border: 1px solid var(--dim-grey);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);
}

.cal-event-simple.draft-event {
  background: repeating-linear-gradient(45deg,
      var(--dim-grey),
      var(--dim-grey) 10px,
      var(--dimmer-grey) 10px,
      var(--dimmer-grey) 20px);
}