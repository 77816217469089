footer {
    margin-top: 4rem;
    background-color: var(--reg-text);
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.footer-block {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.footer-block .footer-title {
    color: var(--white);
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.footer-block a {
    color: var(--light-grey);
    font-size: 1.2rem;
    margin: 0.2rem 0;
}

.footer-block a:hover {
    color: var(--dimmer-grey);
}

.footer-block.scrapika {
    color: var(--dimmer-grey);
    max-width: 200px;
}

.footer-block.scrapika svg {
    height: 4rem;
}

.footer-reviews {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;
}

.footer-reviews a {
    max-width: 80px;
    margin: 0.5rem
}

.footer-reviews a img {
    width: 100%;
    border-radius: 5px;
}