.tagging_title {
    /* color: var(--blue); */
    color: var(--reg-text);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--light-grey);
    width: 100%;
}

.tagging_window {
    max-width: 500px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.tagging_window img {
    width: 100%;
}

.tagging_window .photo_tag {
    position: absolute;
    background-color: var(--reg-text);
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    cursor: move;
    display: flex;
    align-items: center;
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}