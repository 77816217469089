.generic-event-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--dim-grey);
    padding: 1rem;
    max-width: 300px;
    margin: 1rem;
}

.generic-event-header {
    display: flex;
    align-items: flex-start;
}

.generic-event-profile {
    position: relative;
    height: 40px;
    width: 50px;
}

.generic-event-profile-media {
    height: 18px;
    width: 18px;
    border-radius: 9px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--white);
    border: 1px solid var(--white);
    padding: 1px;
}

.generic-event-profile-picture {
    height: 40px;
    width: 40px;
    border-radius: 20px;
}

.generic-event-header-text {
    margin-left: 1rem;
}

.generic-event-social-name {
    color: var(--reg-text);
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 0;
}

.generic-event-pub-date {
    color: var(--grey);
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0;
    padding-top: 0;
}

.generic-event-content {
    display: flex;
    flex-direction: column;
}

.generic-event-content.reversed {
    display: flex;
    flex-direction: column-reverse !important;
}

.generic-event-text-content {
    margin-top: 1rem;
}

.generic-event-text {
    font-size: 1.2rem;
    font-weight: 500;
}

.generic-event-tags {
    color: hsla(214, 89%, 52%, 1);
    font-size: 1.1rem;
}

.generic-event-media-content {
    margin-top: 1rem;
    position: relative;
}

.generic-event-media-content img {
    width: 100%;
    object-fit: contain;
}

.generic-event-media-content video {
    width: 100%;
    object-fit: contain;
}