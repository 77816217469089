.bulk-card {
    background-color: white;
    /* height:400px; */
    width: 256px;
    border: 1px solid #d0d1d5;
    border-radius: 3px;
    /* margin: auto; */
    /* margin-bottom: 10px; */
    margin: 1rem;
    /* padding: 12px; */
    box-shadow: 0 0 5px rgba(0, 0, 0, .30);
    /* Not original, just a test */
    position: relative;
}

.bulk-card .header {
    /* margin-bottom: 17px; */
    padding: 12px;
    margin: 0 0 10px 0;
    /* display: flex; */
    /* align-items: center; */
}

.bulk_ig_icons {
    width: 246px;
    margin: 5px 5px 0px 5px;
}

.bulk-card .ig_likes {
    padding: 0 5px;
    font-size: 1rem;
    color: rgba(var(--f75, 38, 38, 38), 1);
    font-weight: 600;
}

.bulk-card .content {
    clear: both;
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.38;
    padding: 5px;
    margin: 5px 0 0 0;
}

.bulk-card .publication-details {
    padding: 0 5px;
}

.bulk-card .publication-details .social-accounts {
    display: flex;
    flex-wrap: wrap;
}

.bulk-card .publication-details .social-accounts .account_profile_photo {
    width: 40px;
    height: 35px;
}

.bulk-card .publication-details .social-accounts .account_profile_photo .profile_picture {
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.bulk-card .publication-details .social-accounts .account_profile_photo .account_profile_media {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.bulk-card .content .text-mockup {
    width: 100%;
    height: 50px;
    /* background: linear-gradient(90deg, rgba(207,207,207,1) 0%, rgba(213,213,213,1) 35%, rgba(255,255,255,1) 100%); */
    background-color: var(--dim-grey);
}

.bulk-card .content .text {
    white-space: pre-wrap;
}

.bulk-card .content .tags {
    color: hsla(214, 89%, 52%, 1);
}

.bulk-card .img-mockup {
    width: 254px;
    height: 254px;
}

.bulk-card .co-logo-mockup {
    float: left;
    margin-right: 6px;
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.bulk-card .co-logo-mockup img {
    width: 100%;
    border-radius: 1.5rem;
}

.bulk-card .nickname {
    display: block;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 12px;
    /* margin-bottom: 2px; */
    /* margin-left: 38px; */
    margin: 0 0 6px 38px;
    font-weight: 600;
    font-size: 1.2rem;
}

.bulk-card .publication-date {
    display: block;
    /* width: 50px; */
    height: 10px;
    /* background-color: var(--dim-grey); */
    margin-left: 38px;
    font-size: 1rem;
    color: var(--reg-text);
}

.co-name-mockup {
    display: block;
    width: 100px;
    height: 12px;
    background-color: var(--dim-grey);
    margin-bottom: 2px;
    margin-left: 38px;
}

.time-mockup {
    display: block;
    width: 50px;
    height: 10px;
    background-color: var(--dim-grey);
    margin-left: 38px;
}


.video-container {
    position: relative;
}

.video-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .60);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-overlay svg {
    height: 8rem;
    width: 8rem;
    fill: var(--white);
    cursor: pointer;
}

.reference-content {
    border: 2px solid black;
    border: 2px solid rgba(0, 0, 0, .1);
    border-top: 0;
    padding: 10px 12px 10px 12px;
}

.reference:hover .reference-content {
    border-color: black;
    /* Fallback */
    border-color: rgba(0, 0, 0, .15);
}

.reference-title {
    font-family: Helvetica;
    color: #050505;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    -webkit-font-smoothing: antialiased;
}

.reference-subtitle {
    font-family: Helvetica;
    font-size: 14px;
    line-height: 16px;
    color: #65676b;
}

.reference-font {
    color: #90949c;
    font-family: Helvetica;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;

    padding-top: 9px;
}

.social {
    margin-top: 12px;
}

.social-buttons {
    color: #7f7f7f;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;

    border-top: 1px solid #e5e5e5;
    padding-top: 4px;

}

.social-buttons span {
    font-size: 12px;
    margin-right: 20px;
    padding: 4px 4px 4px 0;
}

.social-buttons span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.social-buttons span i {
    padding-right: 4px;
}

/* MEDIA */
.fb-media-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    max-width: 500px;
    max-height: 500px;
    overflow: hidden;
    flex-wrap: wrap;
}

.fb-media-wrapper {
    flex: 1;
    min-width: 50%;
    min-height: 50%;
    position: relative;
}

.fb-media-wrapper img,
.fb-media-wrapper video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 2px;
}

.fb-media-wrapper .video_icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 8rem;
    width: 100%;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    padding: 1rem;
}


.fb-media-wrapper .video_icon svg {
    height: 3rem;
    width: 3rem;
    fill: var(--white);
}

/* PN GENERIC CARD */
/* 
.generic-card-pn {
    background-color: white;
    width: 502px;
    margin: 1rem auto;
    padding: 12px;
    position: relative;
    display: flex;
}

.pn-image-mockup {
    flex: 1;
    width: 250px;
    height: 250px;
    margin-right: 1rem;
    border-radius: 1rem;
    background-color: var(--dim-grey);
}

.pn-media {
    flex: 1;
    max-width: 250px;
}

.pn-media img {
    object-fit: contain;
    margin-right: 1rem;
    border-radius: 1rem;
    max-width: 240px;
}

.pn-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.pn_icons {
    width: 100%;
}

.pn_title_mockup {
    width: 250px;
    height: 50px;
    background-color: var(--dim-grey);
    margin: 1rem 0;
}

.pn_desc_mockup {
    width: 250px;
    height: 100px;
    background-color: var(--dim-grey);
}

.pn-header {
    margin-bottom: 17px;
    margin: 1rem 0;
}





.skeleton-box {
    position: relative;
    overflow: hidden;
    background-color: var(--dim-grey);
}

.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 2s infinite;
    animation: shimmer 2s infinite;
    content: "";
}

@-webkit-keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
} */