.draft_post {
    width: 200px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 0.5rem 1rem;
    box-shadow: 0 4px 8px 0 rgba(199, 156, 156, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    position: relative;

}

.draft_post.draft {
    background: repeating-linear-gradient(45deg,
            var(--dim-grey),
            var(--dim-grey) 10px,
            var(--dimmer-grey) 10px,
            var(--dimmer-grey) 20px);
}

.draft_post.clickable {
    cursor: pointer;
    transition: all 0.1s ease;
}

.draft_post.clickable:hover {
    transform: scale(1.04);
}

.draft_post.selected {
    /* background-color: var(--light-grey); */
    border: 1px solid var(--twitter-blue);
    box-shadow: none;
}

.draft_post .account_header {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    position: relative;
}

.draft_post .account_header .dots_container {
    position: absolute;
    top: 1rem;
    right: 2px;
}

.draft_post .account_header .account_profile_photo {
    position: relative;
    height: 30px;
    width: 40px;
}

.draft_post .account_header .account_profile_photo .profile_picture {
    height: 30px;
    width: 30px;
    border-radius: 20px;
    border: 1px solid var(--light-grey);
}

.draft_post .account_header .account_profile_ai {
    height: 30px;
    width: 30px;
    background-color: var(--white);
    border-radius: 15px;
    border: 1px solid var(--light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
}

.draft_post .account_header .account_profile_ai svg {
    height: 20px;
    fill: var(--grey);
}

.draft_post .account_header .profile_text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0.5rem;
}

.draft_post .account_header .profile_text .acc_name {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--reg-text);
}

.draft_post .account_header .profile_text .pub_date {
    font-size: 1rem;
    font-weight: 500;
    color: var(--reg-text);
}


/* DRAFT VIEW MEDIA STD */

.draft-view-caption {
    width: 200px;
    margin: 2px;
    position: relative;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: default;
    overflow-y: auto;
    overflow-x: hidden;
}

.draft-view-caption svg {
    fill: var(--yellow);
    height: 2rem;
    margin: 0;
}

.draft-view-media {
    width: 200px;
    position: relative;
}

.draft-view-media .hover_caption {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 3;
    opacity: 0;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: default;
    overflow: auto;
}

.draft-view-media:hover .hover_caption {
    opacity: 1;
}

.draft-view-media img {
    width: 200px;
    height: 100%;
    /* object-fit: cover; */
}

.draft-view-media .draft-view-video-container,
.draft-view-media .analytics-view-video-container {
    width: 200px;
    height: 100%;
    position: relative;
}

.draft-view-media .draft-view-video-container video,
.draft-view-media .analytics-view-video-container video {
    width: 200px;
    height: 100%;
    object-fit: cover;
}

.draft-view-media .draft-view-video-container .video_icon,
.draft-view-media .analytics-view-video-container .video_icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 8rem;
    width: 100%;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    padding: 1rem;
}


.draft-view-media .draft-view-video-container .video_icon svg,
.draft-view-media .analytics-view-video-container .video_icon svg {
    height: 3rem;
    width: 3rem;
    fill: var(--white);
}



.draft-view-media .grid-view-video-container {
    width: 200px;
    height: 100%;
    position: relative;
}

.draft-view-media .grid-view-video-container video {
    width: 200px;
    height: 100%;
    object-fit: cover;
}

.draft-view-media .grid-view-video-container .video_overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.draft-view-media .grid-view-video-container .video_overlay svg {
    fill: var(--white);
    height: 4rem;
}




/* DRAFT MODAL */


.draft_modal {
    position: absolute;
    z-index: 10;
    top: 4rem;
    width: 500px;
    /* height: 300px; */
    background-color: var(--white);
    border-radius: 10px;
    border: 1px solid var(--light-grey);
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
    display: flex;
    padding: 2rem;
}