.composer_intro_button {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    width: 400px;
    height: 120px;
    /* border: 2px solid var(--grey); */
    border-radius: 20px;
    padding: 1rem 2rem;
    margin: 2rem;
    transition: all 0.3s ease;
    background-color: var(--card-background-color);
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.composer_intro_button:hover {
    /* transform: scale(1.02); */
    transform: translate(3px, -3px);
}

.composer_intro_button svg {
    height: 4rem;
    fill: var(--grey);
    margin-right: 2rem;
}

.composer_intro_button_content {
    width: 100%;
    height: 100%;
}

.composer_intro_button .title {
    font-size: 2rem;
    font-weight: 700;
    /* color: var(--grey); */
    background: linear-gradient(to top, #3D2BFF, #19A4FE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
}

.composer_intro_button .description {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--reg-text);
    margin-bottom: 1rem;
    height: 5rem;
}

.composer_intro_button .composer_intro_button_content .sn_fit {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 500;
    color: var(--grey);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
    /* height: 100%; */
    /* background-color: red; */
}

.composer_intro_button .sn_fit img {
    height: 1.2rem;
    margin: 0 0.2rem;
    /* opacity: 0.8; */
    /* filter: grayscale(100%) opacity(80%); */
}



.composer_intro_category {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    overflow: hidden;
    /* height: 120px; */
    /* border: 2px solid var(--grey); */
    border-radius: 20px;
    /* padding: 1rem 2rem; */
    margin: 0 2rem 3rem 2rem;
    transition: all 0.3s ease;
    background-color: var(--card-background-color);
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.composer_intro_category:hover {
    /* transform: scale(1.02); */
    transform: translate(3px, -3px);
}

.composer_intro_category img {
    width: 300px;
    height: 200px;
}

.composer_intro_title {
    padding: 1rem;
    font-weight: 700;
    font-size: 2rem;
    background: linear-gradient(to top, #3D2BFF, #19A4FE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.composer_intro_description {
    padding: 0 1rem 1rem 1rem;
    font-weight: 400;
    font-size: 1.2rem;
}


.composer_card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem 20px;
    border-radius: 5px;
    margin: 0 0 2rem 2rem;
    width: 480px;
    height: fit-content;
    color: var(--main-color);
    overflow-x: hidden;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.composer_menu {
    width: 440px;
    background-color: var(--input-background);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1rem 20px;
    position: relative;
    -webkit-box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
    box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
}

.composer_menu_item {
    width: 80px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--grey);
    text-align: center;
    cursor: pointer;
    z-index: 1;
}

.composer_menu_item_row {
    display: flex;
    justify-content: center;
}

.composer_menu_item svg,
.composer_menu_item_row svg {
    height: 2rem;
    fill: var(--grey);
    margin-bottom: 0.5rem;
}

.composer_menu_item.active {
    color: var(--white);
}

.composer_menu_item.active svg {
    fill: var(--white);
}

.composer_menu_item.inactive {
    opacity: 0.2;
    cursor: default;
}



/* INDICATOR FOR 5 ITEMS */

.composer_menu_indicator {
    position: absolute;
    top: 1rem;
    left: 20px;
    width: 80px;
    height: 60px;
    /* background-color: var(--blue); */
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    border-radius: 5px;
    transition: 0.5s;
}

.composer_menu .composer_menu_item~.composer_menu_indicator {
    opacity: 0;
}

.composer_menu .composer_menu_item:nth-child(1).active~.composer_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*0))
}

.composer_menu .composer_menu_item:nth-child(2).active~.composer_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*1))
}

.composer_menu .composer_menu_item:nth-child(3).active~.composer_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*2))
}

.composer_menu .composer_menu_item:nth-child(4).active~.composer_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*3))
}

.composer_menu .composer_menu_item:nth-child(5).active~.composer_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*4))
}

/* INDICATOR FOR 4 ITEMS */

.composer_menu_indicator_4 {
    position: absolute;
    top: 1rem;
    left: 30px;
    width: 80px;
    height: 60px;
    /* background-color: var(--blue); */
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    border-radius: 5px;
    transition: 0.5s;
}


.composer_menu .composer_menu_item~.composer_menu_indicator_4 {
    opacity: 0;
}

.composer_menu .composer_menu_item:nth-child(1).active~.composer_menu_indicator_4 {
    opacity: 1;
    transform: translateX(calc(100px*0))
}

.composer_menu .composer_menu_item:nth-child(2).active~.composer_menu_indicator_4 {
    opacity: 1;
    transform: translateX(calc(100px*1))
}

.composer_menu .composer_menu_item:nth-child(3).active~.composer_menu_indicator_4 {
    opacity: 1;
    transform: translateX(calc(100px*2))
}

.composer_menu .composer_menu_item:nth-child(4).active~.composer_menu_indicator_4 {
    opacity: 1;
    transform: translateX(calc(100px*3))
}

/* COMPONENTS */

.composer_components {
    display: flex;
    flex-direction: flex-start;
    width: 2200px;
}

.composer_component {
    width: 440px;
    opacity: 0;
    transition: 0.5s;
    min-height: 600px;
}

.composer_component.active {
    opacity: 1;
}

.composer_component .title,
.composer_title,
.composer_component_thread .title {
    /* color: var(--blue); */
    color: var(--reg-text);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--light-grey);
}

.composer_component .title_action {
    color: var(--reg-text);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--light-grey);
    cursor: pointer;
    transition: all 0.3s ease;
}

.composer_component .title_action svg {
    height: 2rem;
    fill: var(--reg-text);
    margin-right: 0.5rem;
}

.composer_component .title_action.active svg {
    transform: rotate(90deg);
}

.composer_component .title .title_add {
    color: var(--grey);
    font-weight: 500;
    font-size: 1.2rem;
    margin-left: 1rem;
    text-transform: none;
}

.composer_components .composer_component:nth-child(1).active {
    transform: translateX(calc(440px*0))
}

.composer_components .composer_component:nth-child(2).active {
    transform: translateX(calc(440px*-1))
}

.composer_components .composer_component:nth-child(3).active {
    transform: translateX(calc(440px*-2))
}

.composer_components .composer_component:nth-child(4).active {
    transform: translateX(calc(440px*-3))
}

.composer_components .composer_component:nth-child(5).active {
    transform: translateX(calc(440px*-4))
}



/* PREVIEWS */

.card_preview {
    display: flex;
    flex-direction: column;
    position: relative;
    /* background-color: var(--card-background-color); */
    padding: 0 2rem;
    border-radius: 5px;
    margin: 0 0 2rem 2rem;
    min-width: 20rem;
    max-width: 90vw;
    height: fit-content;
    color: var(--main-color);
    /* box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07); */
}

.composer_component_previews {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: calc(100vw - 800px);
    width: fit-content;
}

.composer_previews {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 0 2rem 4rem;
    height: fit-content;
    width: auto;
}

/* INDIVIDUAL COMPONENTS */

/* Text component */
img.emoji-img {
    height: 20px !important;
    width: 20px !important;
    margin: 2px !important;
}

img.alternative_txt_sm_icon {
    width: 15px;
    height: 15px;
    margin: 0 0.5rem;
    filter: grayscale(100%) opacity(80%);
    cursor: pointer;
}

img.alternative_txt_sm_icon.selected {
    filter: none;
}

/* Media component */

.composer_media_upload_card {
    width: 150px;
    height: 150px;
    border: 3px dashed var(--light-grey);
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    text-align: center;
    color: var(--grey);
    font-weight: 500;
    cursor: pointer;
}

.composer_media_upload_card svg {
    height: 50px;
    fill: var(--light-grey);
}

.composer_media_upload_card.active {
    border: 3px dashed var(--gradient-blue);
    color: var(--gradient-blue);

}

.composer_media_upload_card.active svg {
    fill: var(--gradient-blue);
}

.composer_media_uploaded_list {
    /* width: 280px; */
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.composer_media_unit {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 1rem;
}

.composer_media_unit svg {
    height: 2rem;
    width: 2rem;
    fill: var(--red);
    cursor: pointer;
    position: absolute;
    top: -1rem;
    right: -1rem;
}

.composer_media_unit img {
    max-width: 10rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.composer_media_unit video {
    max-width: 10rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

/* Twitter character count */
.textarea_tw_char_count {
    position: absolute;
    height: 16px;
    bottom: 5px;
    right: 95px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--grey);
}

.textarea_total_char_count {
    position: absolute;
    height: 16px;
    bottom: 5px;
    right: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--grey);
}

.regular_tw_char_count,
.regular_total_char_count {
    /* width: 100%; */
    text-align: right;
    height: 16px;
    bottom: 5px;
    right: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--grey);
}

/* Hashtags */

.hashtags_collection {
    font-size: 1.4rem;
    color: var(--reg-text);
    outline: none;
    padding: 1rem;
    /* display: flex; */
    /* flex-wrap: wrap; */
    min-height: 16rem;
    background-color: var(--dimmer-grey);
    /* border: 2px solid var(--dim-grey); */
    /* border: 4px dashed var(--dim-grey); */
}

.used_hashtag {
    /* background-color: var(--blue); */
    background-color: var(--dim-grey);
    /* background-color: var(--yellow); */
    /* background-color: var(--blue-light); */
    color: var(--reg-text);
    /* color: var(--grey); */
    /* color:var(--white); */
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border-radius: 15px;
    text-align: center;
    width: fit-content;
    margin: 0.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    display: inline-block;
}

.used_hashtag.valid_tag {
    background-color: var(--green);
}

.used_hashtag.sensitive_tag {
    background-color: var(--yellow);
}

.used_hashtag.banned_tag {
    background-color: var(--red);
}

.used_hashtag svg {
    height: 1rem;
    margin-left: 1rem;
    cursor: pointer;
}

.composer_hashtags_popularity_list {
    display: flex;
    flex-wrap: wrap;
}

.composer_hashtags_popularity_list .composer_hashtags_popularity_item {
    background-color: var(--dim-grey);
    padding: 0.5rem 1rem;
    border-radius: 15px;
    width: fit-content;
    margin: 0.2rem;
    font-weight: 500;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* display: inline-block; */
}

.composer_hashtags_popularity_list .composer_hashtags_popularity_item svg {
    height: 1.5rem;
    margin-left: 1rem;
}

.composer_hashtags_popularity_list .composer_hashtags_popularity_item svg.very_high_pop {
    fill: var(--bright-green);
}

.composer_hashtags_popularity_list .composer_hashtags_popularity_item svg.high_pop {
    fill: var(--green);
}

.composer_hashtags_popularity_list .composer_hashtags_popularity_item svg.good_pop {
    fill: var(--yellow);
}

.composer_hashtags_popularity_list .composer_hashtags_popularity_item svg.low_pop {
    fill: var(--red);
}



/* MISC. */

.social_post_char_count {
    position: absolute;
    height: 16px;
    bottom: 5px;
    right: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--grey);
}

.imagekit_upload {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}


/* modal picker centered */

.transparent_background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
}

.centered_picker {
    position: fixed;
    top: 20rem;
    left: 50%;
    padding: 2rem;
    background-color: var(--white);
    z-index: 10;
    border: 1px solid var(--dim-grey);
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.library_picker {
    position: fixed;
    top: 8rem;
    left: 72rem;
    height: 600px;
    padding: 2rem;
    background-color: var(--white);
    z-index: 10;
    border: 1px solid var(--dim-grey);
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.unsplash_picker {
    position: fixed;
    top: 8rem;
    left: 72rem;
    width: 400px;
    height: 500px;
    overflow-y: auto;
    padding: 2rem;
    background-color: var(--white);
    z-index: 10;
    border: 1px solid var(--dim-grey);
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}


/* TAG SELECTOR MODAL */

.media_icons {
    position: absolute;
    height: 20px;
    bottom: 5px;
    left: 0px;
    display: flex;
    align-items: center;
}

.social_post_emoji {
    margin: 0 1rem;
}

.social_post_emoji svg {
    cursor: pointer;
    fill: var(--grey);
    height: 20px;
    width: 20px;
}

.social_post_emoji svg:hover {
    cursor: pointer;
    fill: var(--reg-text);
}

.social_post_ai {
    margin: 0 1rem;
    border: 1px solid var(--grey);
    border-radius: 3px;
    color: var(--grey);
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
}

.social_post_ai svg {
    fill: var(--grey);
    height: 20px;
    width: 20px;
    margin-right: 0.2rem;
}

.social_post_ai:hover {
    border: 1px solid var(--reg-text);
    color: var(--reg-text);
}

.social_post_ai:hover svg {
    cursor: pointer;
    fill: var(--reg-text);
}

.media_icons_alt_text {
    /* position: absolute; */
    height: 20px;
    /* top: 0px; */
    /* left: 0px; */
    display: flex;
    align-items: center;
    margin: 5px 0 0 5px;
}

.tag-wrapper {
    position: relative;
}

.tag_selector {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* width: 100px;
    height: 30px; */
    background-color: var(--white);
    z-index: 1;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.tag_selector.fixed {
    top: 80px;
    left: 130px;
}

.tag_selector .tag_account {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
}

.tag_selector .tag_account .tag_account_profile {
    height: 3rem;
    width: 3rem;
    border-radius: 1.5rem;
    overflow: hidden;
    background-color: var(--light-grey);
    object-fit: contain;
}

.tag_selector .tag_account .tag_account_profile img {
    width: 100%;
}



.tag_selector .tag_account .tag_account_name {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
}

/* Mentions list */

.mentions_list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;

}

.mentions_list .mention_account {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.mentions_list .mention_account .tag_account_profile {
    height: 3rem;
    width: 3rem;
    border-radius: 1.5rem;
    overflow: hidden;
    /* background-color: var(--light-grey); */
    object-fit: contain;
}

.mentions_list .mention_account .tag_account_profile img {
    width: 100%;
}

.mentions_list .mention_account .tag_account_profile svg {
    width: 100%;
    fill: var(--grey);
}

.mentions_list .mention_account .tag_account_name {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.mentions_list .mention_account .tag_product {
    height: 6rem;
    width: 6rem;
    border-radius: 1.5rem;
    overflow: hidden;
    /* background-color: var(--light-grey); */
    object-fit: contain;
}

.mentions_list .mention_account .tag_product img {
    width: 100%;
}

.mentions_list .mention_account .tag_product_name {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mentions_list .mention_account .delete_mention {
    cursor: pointer;
    margin-left: 1rem;
}


.mentions_list .mention_account .delete_mention svg {
    height: 2rem;
    width: 2rem;
    fill: var(--red);
}


.hidden_video_container {
    display: none;
}

.media_choice_column_big {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media_choice_column_big svg {
    cursor: pointer;
    fill: var(--light-grey);
    height: 3rem;
}

.media_choice_column_big svg:hover {
    fill: var(--red);
}