.img_cropper_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    /* background-color: var(--grey); */
    z-index: 10002;
    opacity: 0;
    transition: all 0.3s ease;
}

.img_cropper_bg.active {
    opacity: 0.8;
}

.img_cropper_wrapper {
    width: fit-content;
    width: 80vw;
    height: 80vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index: 10003;
    border: 1px solid var(--grey);
    border-radius: 1rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1000ms ease;
    /* transform-origin: top right; */
    opacity: 1;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.img_cropper_wrapper::-webkit-scrollbar {
    display: none;
}


.img_cropper_wrapper.inactive {
    opacity: 0;
}

.img_cropper_title {
    color: var(--grey);
    font-weight: 500;
    text-align: center;
    padding-bottom: 2rem;
    width: 100%;
}

.img_cropper_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

}

.img_cropper_cropped_img {
    max-width: 80vw !important;
    max-height: 50vh !important;
    width: 100%;
}

/* CROPPING WINDOW */
.cropping_window {
    width: 500px;
    max-height: 500px;
    display: flex;
    justify-content: center;
}

/* ICONS */
.crop_icons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}

.crop_icon {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;
    width: 150px;
}

.crop_icon:hover {
    background-color: var(--dim-grey);
}

.crop_icon svg {
    height: 2rem;
}

.crop_icon.crop_button {
    color: var(--blue);
}

.crop_icon.crop_button svg {
    fill: var(--blue);
}

/* SOURCE PHOTOS */

.sources_photos {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-right: 5rem;
    border-right: 1px dashed var(--dim-grey);
}

.source_photos_stack {
    display: flex;
    flex-wrap: wrap;
}

.source_photo_stack {
    position: relative;
}

.ig_worthy_badge,
.ig_worthy_badge_not {
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: var(--white);
}

.ig_worthy_badge img {
    width: 100%;
}

.ig_worthy_badge_not img {
    width: 100%;
    filter: grayscale(1);
}


.mini-img {
    max-height: 70px;
    max-width: 120px;
    padding: 0.2rem;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mini-img img {
    height: 100%;
    object-fit: cover;
}

.mini-img.selected {
    border: 1px solid var(--light-grey);

}


.mini-img img {
    width: 100%;
    object-fit: contain;
}

/* OTHER */

.mini-images-control {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
}

.final-img {
    width: 100%;
    height: 100%;
}


.source-img {
    width: 100%;
    height: 100%;
    display: none;
}