.day_button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dim-grey);
    /* border: 1px solid var(--green); */
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline:none;
    width:50px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 0.5rem;
    line-height: 1;
}

.day_button.selected{
    background-color: var(--blue);
}