@for $i from 0 through 30 {
    .cal-event-#{$i} {
        position: absolute;
        top: 50px+$i*25px;
        z-index:10+$i;
    }
}

@for $j from 0 through 30 {
    .cal-event-weekly-#{$j} {
        position: absolute;
        top: $j*25px;
        z-index:10+$j;
    }
}