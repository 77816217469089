ul.tools_list {
    list-style: none;
}

ul.tools_list li {
    border-bottom: 1px solid var(--dim-grey);
    padding: 1rem 0;
}

.tools_list_title {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}

.tools_hashtag {
    background-color: var(--dim-grey);
    color: var(--reg-text);
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    text-align: center;
    width: fit-content;
    margin: 0.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.account_block {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 2rem 1rem 0;
    position: relative;
}

.account_block .social_name {
    font-size: 1.4rem;
}

.account_block svg {
    height: 2rem;
    fill: var(--light-grey);
    cursor: pointer;
}

.account_block svg:hover {
    fill: var(--grey);
}

.account_block .account_options {
    position: absolute;
    top: 30px;
    right: 0px;
    background-color: var(--dim-grey);
    color: var(--reg-text);
    padding: 0.5rem 1rem;
    width: fit-content;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    transition: transform 100ms ease;
    transform-origin: top;
    opacity: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.3rem;
}

.account_block .account_options.inactive {
    transform: scale(0);
    opacity: 0;
}

.account_block .account_options svg {
    height: 2rem;
}

.account_block .account_options svg.delete_button {
    fill: var(--red);
}