ul.issues_list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

ul.issues_list li {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.5rem 0;
}

ul.issues_list li .action_main,
.action_main {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;
    background: -webkit-linear-gradient(var(--gradient-blue), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

ul.issues_list li .action_main svg,
.action_main svg {
    fill: var(--red);
    height: 1.8rem;
    margin-right: 1rem;
}

.action_main.all_set svg {
    fill: var(--green);
}

ul.issues_list li .action_main img {
    height: 1.8rem;
    margin-right: 1rem;
}

ul.issues_list li .action_description {
    color: var(--grey);
    font-weight: 500;
    font-size: 1.2rem;
    padding-left: 3rem;
}


.action_link {
    font-weight: 600;
    color: var(--grey);
    font-size: 1.2rem;
    padding: 1rem 3rem;
    cursor: pointer;
}

.action_link::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23222C37'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.action_link:hover::after {
    transform: translateX(3px);
}