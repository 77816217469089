form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

input[type="search"],
input[type="text"],
input[type="text"]:focus,
input[type="text"]:active,
input[type="number"],
input[type="number"]:focus,
input[type="number"]:active,
input[type="date"],
input[type="date"]:focus,
input[type="date"]:active,
input[type="url"],
input[type="url"]:focus,
input[type="url"]:active,
input[type="password"],
input[type="password"]:focus,
input[type="password"]:active,
input[type="datetime-local"],
input[type="datetime-local"]:focus,
input[type="datetime-local"]:active,
input[type="email"],
input[type="email"]:focus,
input[type="email"]:active,
input[type="tel"],
input[type="tel"]:focus,
input[type="tel"]:active {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: fit-content;
    min-width: 20rem;
}

select {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: fit-content;
}

input[type="time"],
input[type="time"]:focus,
input[type="time"]:active {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: fit-content;
    min-width: 10rem;
}

select.time_select,
.time_select {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: fit-content;
    min-width: 4rem;
}

input:disabled {
    background-color: transparent;
    color: var(--grey);
}

input[type="checkbox"] {
    background-color: var(--input-background);
    height: 2rem;
    width: 2rem;
}

.checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 25px;
    /* margin-bottom: 12px; */
    height: 2rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

textarea {
    padding: 10px;
    width: 100%;
    height: 100%;
    /* border: 1px solid var(--blue); */
    color: var(--main-color);
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 1.4rem;
    font-family: inherit;
    resize: none;
    overflow: auto;
    box-sizing: border-box;
    position: relative;
    background-color: var(--input-background);
}

textarea.contentai_headline {
    max-height: 6rem;
    overflow: hidden;
}

textarea.contentai_captions {
    min-height: 10rem;
    overflow: hidden;
}

textarea.caption_ai {
    min-height: 10rem;
    overflow: hidden;
}

input[type="text"].dashboard_input {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: 100%;
    min-width: auto;
}

input[type="text"].time_small {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: 10rem;
    min-width: 10rem;
}

input[type="text"].long_input {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem);
    width: fit-content;
    min-width: 30rem;
}

input[type="text"].long_input_copy {
    background-color: var(--input-background);
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: var(--main-color);
    outline: none;
    font-size: 1.2rem;
    width: fit-content;
    min-width: 40rem;
}

.form_row {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5rem 0;
}

.form_row_checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0;
}

.form_row_save_input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form_row_save_input svg {
    fill: var(--light-grey);
    height: 3rem;
    cursor: pointer;
}

.form_row_save_input svg:hover {
    fill: var(--blue);
}


.form_row.hidden {
    display: none;
}


.form_row_textarea {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1rem 0;
    position: relative;
    width: 100%;
    min-height: 28rem;
    min-width: 30rem;
    padding-bottom: 3rem;
    background-color: var(--input-background);
}

.form_col_textarea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1rem 0;
    position: relative;
    width: 100%;
    min-height: 18rem;
    min-width: 30rem;
    padding-bottom: 3rem;
    background-color: var(--input-background);
}

.form_row_textarea_small {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1rem 0;
    position: relative;
    width: 100%;
    min-height: 10rem;
    min-width: 30rem;
    padding-bottom: 1rem;
    background-color: var(--input-background);
}

.form_row_input_content_ai {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    /* min-height: 4rem; */
    /* min-width: 30rem; */
    padding-bottom: 1rem;
    /* background-color: red; */
    background-color: var(--input-background);
    border-radius: 5px;
}

.form_row_input_content_ai.small {
    width: 20rem;
}

.input_total_char_count {
    position: absolute;
    height: 16px;
    bottom: 5px;
    right: 5px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--grey);
}

.form_row_textarea_content_ai {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    border-radius: 5px;
    min-height: 11rem;
    /* min-width: 30rem; */
    padding-bottom: 3rem;
    background-color: var(--input-background);
}

.form_row_textarea_caption_ai {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    border-radius: 5px;
    min-height: 11rem;
    padding-bottom: 3rem;
    background-color: var(--input-background);
}

.form_row_textarea_big {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1rem 0;
    position: relative;
    width: 100%;
    min-height: 30rem;
    min-width: 30rem;
    padding-bottom: 3rem;
    background-color: var(--input-background);
}

.form_row_textarea_advanced {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: flex-start;
    margin: 1rem 0;
    position: relative;
    width: 100%;
    min-width: 30rem;
    min-height: 20rem;
}


label {
    color: var(--reg-text);
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

label sup {
    color: var(--red);
    font-size: 1.2rem;
}

label img {
    height: 2rem;
    margin: 0 0.5rem;
}

.radio_input {
    display: flex;
    align-items: center;
    color: var(--reg-text);
    font-size: 1.2rem;
}

button.green_button {
    display: flex;
    align-items: center;
    background-color: var(--green);
    border: 1px solid var(--green);
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;
}

button.green_button svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

button.green_button:hover {
    color: var(--green);
    background-color: transparent;
}

button.green_button:hover svg {
    fill: var(--green);
}

button.red_button {
    display: flex;
    align-items: center;
    background-color: var(--red);
    border: 1px solid var(--red);
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;
}

button.red_button svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

button.red_button:hover {
    color: var(--red);
    background-color: transparent;
}

button.red_button:hover svg {
    fill: var(--red);
}

button {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: all 0.3s;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

button svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

button:hover {
    cursor: pointer;
    background-color: transparent;
    color: var(--blue);
}

button:hover svg {
    fill: var(--blue);
}

button.light {
    background-color: transparent;
    border: 2px solid var(--blue);
    color: var(--blue);
    font-weight: 700;
    border-radius: 5px;
    transition: all 0.3s;
    padding: 10px;
    /* text-transform: uppercase; */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* IMAGEKIT */

.file-input label.imagekit_upload_label {
    display: flex;
    align-items: center;
    background-color: var(--green);
    border: 1px solid var(--green);
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 1rem;
    cursor: pointer;
    margin-right: 1rem;
    height: 100%;
}

.file-input label.imagekit_upload_label svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

.file-input label.imagekit_upload_label:hover {
    color: var(--green);
    background-color: transparent;
}

.file-input label.imagekit_upload_label:hover svg {
    fill: var(--green);
}