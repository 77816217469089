.promo_card {
    display: flex;
    flex-direction: column;
    position: relative;
    /* background-color: var(--card-background-color); */
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    padding: 2rem;
    border-radius: 5px;
    margin: 0 0 2rem 2rem;
    min-width: 20rem;
    max-width: 90vw;
    height: fit-content;
    color: var(--main-color);
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
    /* background: linear-gradient(-45deg, #743ad5, var(--blue)); */
}

.promo_card .title {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--white);
    /* width: 90%; */
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
}

.promo_card .title svg {
    height: 3rem;
    fill: var(--yellow);
}

.promo_card .list {
    margin: 2rem auto;
    width: 80%;
}

.promo_card .list ul {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0 2rem;
    line-height: 1.8;
    margin-top: 1rem;
    color: var(--white);
}

/* .promo_card .list ul li {
    text-transform: capitalize;
} */