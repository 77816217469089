.accounts_column {
    width: 240px;
    min-width: 240px;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    height: 650px;
    overflow-y: auto;
    background-color: var(--white);
    border-radius: 5px;
    padding: 2rem 1rem;
    -webkit-box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.account_group_header {
    display: flex;
    align-items: center;
    height: 3rem;
    color: var(--grey);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
}

.account_group_header svg {
    transition: all 0.3s ease;
}

.account_group_header.selected svg {
    transform: rotateZ(90deg);
}

.account_group_header svg {
    height: 2rem;
    fill: var(--grey);
    margin-right: 1rem;
}

ul.accounts_list {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    padding: 0;
}

ul.accounts_list.selected {
    /* height: 300px; */
    /* overflow-y: auto; */
    height: auto;
}

ul.accounts_list li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0rem;
    padding: 0.5rem 1rem;
    color: var(--reg-text);
    cursor: pointer;
    /* border-radius: 5px; */
}

ul.accounts_list li:hover,
ul.accounts_list li.selected {
    background-color: var(--dimmer-grey);
    border-left: 2px solid var(--twitter-blue);
}

ul.accounts_list li .account_block_inbox {
    display: flex;
    align-items: flex-start;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
}

ul.accounts_list li .account_block_inbox .account_profile_photo_inbox {
    position: relative;
    height: 30px;
    width: 40px;
    margin-right: 5px;
}

ul.accounts_list li .account_block_inbox .account_profile_name {
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

ul.accounts_list li .account_block_inbox .account_profile_photo_inbox .profile_picture_inbox {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid var(--light-grey);
}

ul.accounts_list li .account_block_inbox svg.engagement_notif_dot {
    height: 10px;
    width: 10px;
    fill: var(--red);
    margin-left: auto;
}

/* MESSAGE LIST */

.msg_list_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 350px;
    margin: 0 2rem;
}

.msg_list_column .title {
    font-size: 3rem;
    color: var(--grey);
    letter-spacing: -1px;
    margin-bottom: 3rem;
}

.no_msg_list {
    width: 100%;
    background-color: var(--white);
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    margin: 1rem 0;
    cursor: pointer;
}

.no_msg_list svg {
    fill: var(--grey);
    height: 3rem;
    margin-right: 1rem;
}

.msg_list_item {
    width: 100%;
    background-color: var(--white);
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 1rem 0;
    cursor: pointer;
}

.msg_list_post_preview {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    overflow: hidden;
}

.msg_list_post_preview img {
    width: 100%;
    object-fit: cover;
}

.msg_list_item.selected {
    border-bottom: 4px solid var(--twitter-blue);
}

.msg_list_account_data {
    display: flex;
    align-items: flex-start;
}

.msg_list_account_data img.msg_list_profile_picture {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 1rem;
}

.msg_list_account_data .msg_list_account_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.msg_list_account_data .msg_list_account_header .msg_list_handle {
    color: var(--grey);
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.msg_list_account_data .msg_list_account_header .msg_list_time {
    color: var(--grey);
    font-size: 1.1rem;
    font-weight: 400;
}

.msg_list_item .msg_list_comment_preview {
    margin-top: 1rem;
    font-size: 1.3rem;
    color: var(--grey);
    font-weight: 400;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* MESSAGE ACTION */

.msg_action_column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* width: 100%; */
    margin: 0 1rem;
}



.msg_action_column .msg_action_post,
.msg_action_column .msg_action_comment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 1rem 1rem 1rem;
}

.msg_action_column .msg_action_post {
    width: 330px;
}

.msg_action_column .msg_action_comment {
    width: 500px;
}


.msg_action_column .msg_action_post .title,
.msg_action_column .msg_action_comment .title {
    font-size: 3rem;
    color: var(--grey);
    letter-spacing: -1px;
    margin-bottom: 3rem;
}

.msg_action_column .comment_wrapper {
    width: 500px;
    background-color: var(--white);
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 1rem 0;
    box-sizing: border-box;
}

.msg_action_column .comment_wrapper .msg_list_comment_full {
    margin-top: 1rem;
    font-size: 1.3rem;
    color: var(--grey);
    font-weight: 400;
    /* margin-left: 5rem; */
    width: 100%;
    padding-left: 5rem;
}

.replies_container {
    width: 100%;
    padding-left: 5rem;
}

.reply_wrapper {
    width: 100%;
    background-color: var(--dim-grey);
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 1rem;
    /* margin-left: 4rem; */
}

.reply_wrapper .msg_list_comment_full_reply {
    margin-top: 1rem;
    font-size: 1.3rem;
    color: var(--grey);
    font-weight: 400;
    width: 100%;
}

/* STAR RATING GMB */

.gmb_rating {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.gmb_star {
    fill: #FFBF00;
    height: 1.8rem;
}


@media screen and (max-width: 1730px) {
    .msg_action_column .msg_action_post {
        display: none;
    }
}