.cropper_source_img {
    width: 100%;
    height: 100%;
    display: none;
}


.cropper_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    z-index: 10002;
    opacity: 0;
    transition: all 0.3s ease;
}

.cropper_bg.active {
    opacity: 0.8;
}

.cropper_wrapper {
    width: fit-content;
    min-width: 600px;
    /* width: 80vw; */
    max-height: 80vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index: 10003;
    border: 1px solid var(--grey);
    border-radius: 1rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1000ms ease;
    opacity: 1;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.cropper_wrapper::-webkit-scrollbar {
    display: none;
}

.cropper_wrapper.inactive {
    opacity: 0;
}

.cropper_source_photos {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    max-width: 600px;
    overflow-x: auto;
    height: 90px;
    /* background-color: red; */
}

.cropper_source_photos .cropper_source_photo {
    height: 70px;
    width: fit-content;
    border: 2px solid transparent;
    cursor: pointer;
    margin: 0 1rem;
}

.cropper_source_photos .cropper_source_photo.selected {
    border: 2px solid var(--gradient-blue);
}

.cropper_source_photos .cropper_source_photo img {
    height: 100%;
}

/* WINDOW */

.cropper_cropping_window {
    /* max-width: 600px !important; */
    max-height: 400px !important;
    object-fit: contain;
    /* width: 100%; */
    /* object-fit: cover; */
}

.cropper_cropping_window img {
    max-height: 400px;
}


/* ICONS */

.cropper_icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cropper_icon {
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;
    width: 150px;
}

.cropper_icon:hover {
    background-color: var(--dim-grey);
}

.cropper_icon svg {
    height: 2rem;
}

.cropper_icon.selected {
    color: var(--gradient-blue);
}

.cropper_icon.selected svg {
    fill: var(--gradient-blue);
}

.crop_icon.crop_button {
    color: var(--blue);
}

.cropper_icon.crop_button svg {
    fill: var(--blue);
}


/* VIDEO CHECKER */

.video_container {
    max-width: 400px;
}

.video_container video {
    width: 100%;
    max-height: 250px;
}

.captured_thumbnail {
    max-width: 400px;
}

.captured_thumbnail img {
    max-height: 250px;
}


.thumbnail_capture_mockup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--border-color);
    padding: 1rem;
    /* max-height: 250px; */
    max-width: 400px;
}

.thumbnail_capture_mockup.active {
    border-color: var(--twitter-blue);
}

.thumbnail_capture_mockup svg {
    height: 2rem;
    fill: var(--light-grey);
}