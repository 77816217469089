.event_modal_background{
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 100vw;
    z-index:10002;
    background-color: #000;
    opacity: 0.7;
}

.event_modal_wrapper{
    width: fit-content;
    max-width:80vw;
    max-height: 80vh;
    overflow-y: scroll;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index:10003;
    border: 1px solid var(--grey);
    border-radius: 1rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1000ms ease;
    opacity: 1;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

.event_modal_wrapper::-webkit-scrollbar{
    display: none;
}

.event_modal_row{
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
}