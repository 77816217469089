.onoffswitch {
    position: relative;
    width: 60px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 36px;
    padding: 0;
    line-height: 36px;
    border: 2px solid #C8CAD4;
    border-radius: 33px;
    background-color: #FFFFFF;
    transition: background-color 0.3s ease-in;
}

.onoffswitch-label:before {
    content: "";
    display: block;
    width: 36px;
    margin: 0px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 3px;
    right: 24px;
    border: 2px solid #C8CAD4;
    border-radius: 33px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label {
    background-color: #5BC0BE;
    /* background-image: linear-gradient(to top, #3D2BFF, #19A4FE); */
}

.onoffswitch-checkbox:checked+.onoffswitch-label,
.onoffswitch-checkbox:checked+.onoffswitch-label:before {
    border-color: #5BC0BE;
    /* background-image: linear-gradient(to top, #3D2BFF, #19A4FE); */
}

.onoffswitch-checkbox:checked+.onoffswitch-label:before {
    right: 0px;
}