.onoffswitch_draft {
    position: relative;
    width: 40px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch_draft-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.onoffswitch_draft-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--light-grey);
    border-radius: 12px;
}

.onoffswitch_draft-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch_draft-inner:before,
.onoffswitch_draft-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 19px;
    padding: 0;
    line-height: 19px;
    font-size: 14px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch_draft-inner:before {
    content: "";
    padding-left: 10px;
    /* background-color: #5BC0BE; */
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
}

.onoffswitch_draft-inner:after {
    content: "";
    padding-right: 10px;
    background-color: var(--input-background);
    text-align: right;
}

.onoffswitch_draft-switch {
    display: block;
    width: 14px;
    height: 14px;
    margin: 3px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 17px;
    border: 1px solid var(--grey);
    border-radius: 7px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch_draft-checkbox:checked+.onoffswitch_draft-label .onoffswitch_draft-inner {
    margin-left: 0;
}

.onoffswitch_draft-checkbox:checked+.onoffswitch_draft-label .onoffswitch_draft-switch {
    right: 0px;
}