.modal_background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* background-color: var(--grey); */
  z-index: 10002;
  /* opacity: 0.8; */
  background-color: #000;
  opacity: 0;
  transition: all 0.3s ease;
}

.modal_background.active {
  opacity: 0.7;
}

.modal_wrapper {
  width: fit-content;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  z-index: 10003;
  border: 1px solid var(--dim-grey);
  border-radius: 1rem;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1000ms ease;
  opacity: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.help_modal_wrapper {
  width: 60vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  z-index: 10003;
  border: 1px solid var(--dim-grey);
  border-radius: 1rem;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1000ms ease;
  opacity: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal_wrapper.inactive {
  opacity: 0;
}

.modal_wrapper::-webkit-scrollbar {
  display: none;
}


.modal_wrapper h2,
.modal_wrapper h4 {
  text-align: center;
}

.close_modal {
  color: var(--light-grey);
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 3rem;
  font-weight: 500;
  z-index: 10000;
}

.close_modal:hover,
.close_modal:focus {
  color: var(--reg-text);
  text-decoration: none;
  cursor: pointer;
}

.modal_title {
  color: var(--blue);
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.modal_content {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 80vw;
  align-items: center;
}

.modal_content svg {
  height: 6rem;
}

.modal_content img {
  width: 100%;
}

.modal_content h1 {
  color: var(--grey);
  font-size: 2rem;
  font-weight: 500;
}

.modal_message {
  margin-top: 3rem;
  font-size: 1.5rem;
  text-align: left;
  color: var(--reg-text);
}

.modal_button {
  margin-top: 4rem;
}

.modal_buttons {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  width: 100%;
}


/* LOADING ANIMATION */

.modal_wrapper_loader {
  width: fit-content;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  z-index: 10003;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1000ms ease;
  opacity: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal_wrapper_loader.inactive {
  opacity: 0;
}

.lds-ring-loader-white {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring-loader-white div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--white);
  border-radius: 50%;
  animation: lds-ring-loader-white 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--white) transparent transparent transparent;
}

.lds-ring-loader-white div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring-loader-white div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring-loader-white div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring-loader-white {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


img.modal_illustration {
  max-height: 25rem;
}

img.illustration {
  height: 20rem;
  width: 100%;
}





.welcome_modal_wrapper {
  width: 600px;
  height: 500px;
  position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  z-index: 10003;
  border: 1px solid var(--dim-grey);
  border-radius: 1rem;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1000ms ease;
  opacity: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.welcome_modal_wrapper.inactive {
  opacity: 0;
}

.welcome_modal_wrapper::-webkit-scrollbar {
  display: none;
}

.welcome_modal_wrapper img {
  width: 600px;
  height: 300px;
}

.welcome_modal_wrapper .welcome_title {
  font-size: 2rem;
  padding: 1rem 2rem;
  margin-top: 1rem;
  font-weight: 700;
  color: var(--reg-text);
}

.welcome_modal_wrapper .welcome_desc {
  font-size: 1.4rem;
  padding: 1rem 2rem;
  font-weight: 400;
  color: var(--grey);
}

.welcome_items_wrapper {
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.welcome_items {
  width: 1800px;
  height: 500px;
  background-color: var(--white);
  display: flex;
  align-items: flex-start;
  position: relative;
}

.welcome_item {
  height: 500px;
  width: 600px;
  opacity: 0;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.welcome_item.active {
  opacity: 1;
}

.welcome_items .welcome_item:nth-child(1).active {
  transform: translateX(calc(600px*0))
}

.welcome_items .welcome_item:nth-child(2).active {
  transform: translateX(calc(600px*-1))
}

.welcome_items .welcome_item:nth-child(3).active {
  transform: translateX(calc(600px*-2))
}

.welcome_nav {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 600px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome_nav .welcome_nav_dot {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: var(--gradient-blue);
  opacity: 0.5;
  cursor: pointer;
  margin: 0 0.5rem;
  transition: all 0.3s ease;
}

.welcome_nav .welcome_nav_dot.active {
  opacity: 1;
  cursor: default;
}