section.dashboard {
  display: grid;
  /* background-color: var(--light-grey); */
  min-height: 100vh;
  width: 100%;
  padding: 0;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "dashboard-content"
}

.dasboard-content {
  grid-area: dashboard-content;
  padding: 0 4rem 0 0;
  /* padding: 2rem; */
  /* background-color: red; */
}

.dashboard-profile-grid {
  grid-area: dashboard-profile;
  margin: 0 4rem;
}

.dashboard-title {
  color: var(--dark-blue);
  font-weight: 300;
  font-size: 4rem;
  margin-bottom: 1rem;
}

.dashboard-profile-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--dim-grey);
  padding: 2rem;
  border-radius: 5px;
  /* margin-top: 4rem; */
}


.profile_icon_big {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  font-size: 3.2rem;
  font-weight: 700;
  color: var(--white);
  border: none;
  position: relative;
}

.profile_blue {
  background-image: linear-gradient(-45deg, #743ad5, #d53a9d);
}

.profile_green {
  background-image: linear-gradient(-45deg, #3D2BFF, #B2FF59);
}

.profile_icon_big img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 60px;

}

.profile_update_button {
  position: absolute;
  bottom: 0;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}

.profile_update_button svg {
  height: 1.6rem;
  width: 1.6rem;
  fill: var(--white);
}

.file-input-profile-pic label.imagekit_upload_label {
  position: absolute;
  bottom: 0;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}

.file-input-profile-pic label.imagekit_upload_label svg {
  height: 1.6rem;
  width: 1.6rem;
  fill: var(--white);
}

.file-input-profile-pic label.imagekit_upload_label:hover {
  background-color: var(--white);
}

.file-input-profile-pic label.imagekit_upload_label:hover svg {
  fill: var(--green);
}

.meter {
  box-sizing: content-box;
  height: 10px;
  /* Can be anything */
  position: relative;
  background: var(--light-grey);
  border-radius: 15px;
  padding: 2px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  width: 100%;
  margin-right: 2rem;
}

.meter.meter_small {
  height: 5px;
  border-radius: 8px;
}

.meter>span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(center bottom,
      rgb(43, 194, 83) 37%,
      rgb(84, 240, 84) 69%);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}

.meter>span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent);
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.meter_red>span {
  background-color: var(--red);
}

.meter_yellow>span {
  background-color: var(--yellow);
}

.meter_green>span {
  background-color: var(--green);
}

.social_make_admin_button {
  fill: var(--blue);
  height: 2rem;
  opacity: 0.5;
  cursor: pointer;
}

.social_delete_button {
  fill: var(--red);
  height: 2rem;
  opacity: 0.5;
  cursor: pointer;
}

.social_delete_button_onboarding {
  fill: var(--white);
  height: 2rem;
  opacity: 0.6;
  cursor: pointer;
}

.social_delete_button:hover,
.social_delete_button_onboarding:hover,
.social_make_admin_button:hover {
  opacity: 1;
}


.learn_more {
  font-weight: 600;
  color: var(--grey);
  font-size: 1.2rem;
}

.learn_more.learn_more_white {
  color: var(--white);
}

.learn_more::after {
  content: ' ';
  position: relative;
  top: 0px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23222C37'/%3E%3C/svg%3E%0A");
  height: 16px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
  transition: transform 0.25s ease-in-out;
}

.learn_more.learn_more_white::after {
  content: ' ';
  position: relative;
  top: 0px;
  width: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
  height: 16px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
  transition: transform 0.25s ease-in-out;
}

.learn_more:hover::after {
  transform: translateX(3px);
}

.title_gradient {
  font-size: 1.6rem;
  font-weight: 700;
  background: -webkit-linear-gradient(var(--gradient-blue), var(--blue));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reviews-container {
  display: flex;
  width: 100%;
  /* flex-wrap: nowrap; */
  justify-content: space-around;
  padding: 1rem;
}

.reviews-container a {
  min-width: 80px;
  margin: 0 1rem;
}

.reviews-container a img {
  max-width: 150px;
  width: 100%;
  /* width: auto; */
}