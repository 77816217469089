.tooltip_help_wrapper {
    /* display: inline-block; */
    position: relative;
    display: flex;
    align-items: center;
  }

  .tooltip_help_tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    color: var(--white);
    background: var(--red);
    font-size: 1.2rem;
    font-family: var(--montsert);
    font-weight: 700;
    line-height: 1.4;
    z-index: 1;
    /* white-space: nowrap; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    height: fit-content;
    text-transform: none;
  }
  
  /* CSS border triangles */
  .tooltip_help_tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
  }
  
  /* Absolute positioning */
  .tooltip_help_tip.top {
    top: calc(var(--tooltip-margin) * -1);
  }
  /* CSS border triangles */
  .tooltip_help_tip.top::before {
    top: 100%;
    border-top-color: var(--red);
    /* border-top-color: var(--tooltip-background-color); */
  }
  
  /* Absolute positioning */
  .tooltip_help_tip.right {
    left: calc(100% + var(--tooltip-help-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .tooltip_help_tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--red);
    /* border-right-color: var(--tooltip-background-color); */
  }
  
  /* Absolute positioning */
  .tooltip_help_tip.bottom {
    top: 30px;
    left:20px;
  }
  /* CSS border triangles */
  .tooltip_help_tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--red);
    /* border-bottom-color: var(--tooltip-background-color); */
  }
  
  /* Absolute positioning */
  .tooltip_help_tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .tooltip_help_tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--red);
  }
  