.tz-dropdown-main {
    width: 100%;
    height: fit-content;
    background-color: var(--white);
    cursor: pointer;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.selected-tz {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.selected-tz svg {
    height: 2rem;
    fill: var(--grey);
}

ul.tz-dropdown-list {
    list-style: none;
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 10;
    width: 100%;
    margin: 0;
    padding: 0;
    display: none;
    border: 1px solid var(--border-color);
    height: 200px;
    overflow-y: auto;
}

ul.tz-dropdown-list.displayed {
    display: block;
}

ul.tz-dropdown-list li {
    background-color: var(--dimmer-grey);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem;
    padding: 0.5rem 2rem;
    border-bottom: 1px dotted var(--light-grey);
}

ul.tz-dropdown-list li.divider {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 0px;
    background-color: none;
    border-top: solid 2px var(--grey);
}

ul.tz-dropdown-list li:hover,
ul.tz-dropdown-list li.selected {
    background-color: var(--border-color);
}

ul.tz-dropdown-list li.selected {
    cursor: default;
}

ul.tz-dropdown-list li:last-child {
    border-bottom: none;
}