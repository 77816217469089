header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    /* background-color: var(--white); */
    /* border-bottom:1px solid #eceef3; */
    background-color: var(--background-color);
    /* border-bottom: 1px solid var(--grey); */
}

.header_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    /* margin: 0 1rem; */
    /* background-color: #3D2BFF; */
    /* background-color: #ffffffcb; */
    background-color: var(--white);
    height: 100%;
    width: 100%;
    -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    /* box-shadow:
        0px 0.8px 2.2px -18px rgba(0, 0, 0, 0.02),
        0px 1.9px 5.3px -18px rgba(0, 0, 0, 0.028),
        0px 3.6px 10px -18px rgba(0, 0, 0, 0.035),
        0px 6.5px 17.9px -18px rgba(0, 0, 0, 0.042),
        0px 12.1px 33.4px -18px rgba(0, 0, 0, 0.05),
        0px 29px 80px -18px rgba(0, 0, 0, 0.07); */


}

.header_wrapper .brand {
    margin-top: 0.5rem;
    /* width: 4rem; */
    height: 4rem;
    cursor: pointer;
    /* margin-left: 1rem; */
}

.header_wrapper .brand svg {
    height: 4rem;
}


.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    color: var(--white);
}

.logo svg {
    height: 3rem;
    fill: var(--white);
    stroke: var(--white);
    margin-right: 1rem;
}

header .title {
    color: var(--grey);
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

header .profile {
    display: flex;
    align-items: center;
}

header .profile .header-profile-button,
header .profile .header-profile-button a {
    position: relative;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    color: var(--grey);
    font-size: 1.2rem;
    cursor: pointer;
}

header .profile .header-profile-button .notification {
    background-color: var(--red);
    padding: 0.1rem 0.5rem;
    color: var(--white);
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    position: absolute;
    top: -8px;
    right: -8px;
    min-width: 20px;
}

header .profile .header-profile-button svg {
    height: 2rem;
    fill: var(--grey);
    margin-right: 0.5rem;
}

header .profile .header-profile-button.help-center:hover,
header .profile .header-profile-button.help-center:hover a {
    color: var(--red);
}

header .profile .header-profile-button.help-center:hover svg {
    fill: var(--red);
}

header .profile .profile_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* background-color: var(--background-color); */
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--white);
    /* border:1px solid var(--white); */
    border: none;
    margin-left: 3rem;
}

header .profile .profile_icon img {
    width: 40px;
}

header .profile .chevron {
    width: 20px;
    height: 20px;
    fill: var(--grey);
    cursor: pointer;
}

.header_menu {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100%;
    /* border-right: 1px dotted var(--light-grey);
    border-left: 1px dotted var(--light-grey); */
    padding: 5px 10px;

}

.header_menu svg {
    height: 2rem;
    fill: var(--light-grey);
}

.header_menu:hover {
    color: var(--grey);
}

.header_menu:hover svg {
    fill: var(--grey);
}

.header_menu.active {
    -webkit-box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
    box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
    color: var(--red);
    border: none;
}

.header_menu.active svg {
    fill: var(--red);
}



.profile .profile_menu {
    position: absolute;
    top: 5.3rem;
    right: 2rem;
    background-color: var(--dim-grey);
    color: var(--reg-text);
    padding: 1rem;
    width: fit-content;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    transition: transform 100ms ease;
    transform-origin: top;
    opacity: 1;
}

.profile .profile_menu.inactive {
    transform: scale(0);
    opacity: 0;
}

.profile .profile_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile .profile_menu ul li {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    color: var(--grey);
    opacity: 0.8;
}

.profile .profile_menu ul li svg {
    height: 2rem;
    fill: var(--grey);
}

.profile .profile_menu ul li:hover {
    opacity: 1;
    /* color: var(--reg-text); */
}

/* .profile .profile_menu ul li:hover svg {
    fill: var(--reg-text);
} */

.profile .profile_menu ul li.language_selection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* font-size: 1.4rem; */
    font-weight: 500;
    cursor: pointer;
    color: var(--reg-text);
    margin-top: 1rem;
    opacity: 1;
}

.profile .profile_menu ul li.language_selection img {
    width: 20px;
    cursor: pointer;
}

.profile .profile_menu ul li.language_selection p {
    opacity: 0.8;
}

.profile .profile_menu ul li.language_selection p:hover {
    color: var(--blue);
    opacity: 1;
}

.profile .profile_menu ul li.signout {
    font-weight: 500;
    opacity: 0.6;
}

.profile .profile_menu ul li.signout svg {
    fill: var(--red);
}

.profile .profile_menu ul li.signout:hover {
    opacity: 1;
}

.test_link {
    display: flex;
    align-items: center;
    color: var(--grey);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
}


/* NOTIFICATIONS */


.notification_list {
    position: absolute;
    top: 2rem;
    right: 0rem;
    background-color: var(--dim-grey);
    padding: 1rem;
    width: fit-content;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    transition: transform 100ms ease;
    transform-origin: top right;
    opacity: 1;
}

.notification_list.inactive {
    transform: scale(0);
    opacity: 0;
}

.notification_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 2;
}

.notification_list ul li:last-child {
    border: none;
}

.notification_list ul li {
    /* cursor: pointer; */
    min-width: 200px;
    color: var(--grey);
    font-weight: 500;
    border-bottom: 1px solid var(--light-grey);
}


a.promo_row {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    /* background-image: linear-gradient(-45deg, #743ad5, #d53a9d); */
    color: var(--white);
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;
    width: 100%;
}

a.promo_row svg {
    height: 2rem;
    fill: var(--yellow);
    margin: 0 1rem;
}

a.promo_row:hover {
    background-color: var(--white);
    background-image: none;
    border: 1px solid var(--dark-blue);
    color: var(--dark-blue);
}

.current_plan {
    display: flex;
    align-items: center;
    color: var(--reg-text);
    font-weight: 500;
    font-size: 1.2rem;
    background-color: var(--green-light);
    padding: 0.5rem 1rem;
    border-radius: 15px;
    cursor: pointer;
}

.current_plan .plan_details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.current_plan .plan_details p {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
}

.current_plan .upgrade {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-left: 1rem;
}

.current_plan .upgrade svg {
    height: 1.5rem;
    fill: var(--reg-text);
}


.header_third_title {
    display: flex;
    align-items: center;
    color: var(--reg-text);
    font-size: 1.8rem;
    height: 100%;
}

@media screen and (max-width: 768px) {
    header {
        display: none;
    }
}