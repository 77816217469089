/* PRICING FEATURE TABLE */

.pricing-feature-table {
    width: 100%;
    display: table;
}

.pft-body {
    display: table-row-group;
}

.pft-row {
    display: flex;
    border-bottom: 1px dotted var(--dim-grey);
    padding: 0.5rem 0;
}

.pft-body-cell {
    display: flex;
    flex: 1;
    color: var(--grey);
    padding: 0 1rem;
    margin: 0;
}

.pft-body-cell.title {
    color: var(--black);
    padding: 0;
}

.pft-body-cell.plan {
    color: var(--blue);
    font-size: 1.8rem;
    padding: 0;
}

.pft-body-cell.svg-green svg {
    fill: var(--green);
    width: 1.5rem;
}

.pft-body-cell.svg-red svg {
    fill: var(--red);
    width: 1.5rem;
}

.pft-body-cell.icons {
    display: flex;
}

.pft-body-cell.icons img {
    width: 1.5rem;
    margin-right: 0.5rem;
}