.onboarding_wrapper {
    margin: 0;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    display: flex;
    justify-content: flex-start;
    position: relative;
}

.onboarding_nav {
    width: 200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

.onboarding_nav svg.svg_nav {
    opacity: 1;
    height: 5rem;
    fill: var(--white);
    cursor: pointer;
    margin: 1rem 0;
    transition: 0.3s;
}

.onboarding_nav svg.svg_nav.hidden {
    opacity: 0;
}

.onboarding_nav .onboarding_nav_dot {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: var(--white);
    opacity: 0.5;
    cursor: pointer;
    margin: 0.5rem 0;
    transition: all 0.3s ease;
}

.onboarding_nav .onboarding_nav_dot.active {
    opacity: 1;
    cursor: default;
}

.onboarding_items_wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    /* background-color: red; */
}

.onboarding_items {
    width: 100%;
    height: 600vh;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.onboarding_item {
    height: 100vh;
    width: 100%;
    opacity: 0;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onboarding_item.active {
    opacity: 1;
}

.onboarding_items .onboarding_item:nth-child(1).active {
    transform: translateY(calc(100vh*0))
}

.onboarding_items .onboarding_item:nth-child(2).active {
    transform: translateY(calc(100vh*-1))
}

.onboarding_items .onboarding_item:nth-child(3).active {
    transform: translateY(calc(100vh*-2))
}

.onboarding_items .onboarding_item:nth-child(4).active {
    transform: translateY(calc(100vh*-3))
}

.onboarding_items .onboarding_item:nth-child(5).active {
    transform: translateY(calc(100vh*-4))
}

.onboarding_items .onboarding_item:nth-child(6).active {
    transform: translateY(calc(100vh*-5))
}


/* ONBOARDING COMPONENTS */

.onboarding_content {
    color: var(--white);
    font-size: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    width: 100%;
}

.onboarding_content .onboarding_qa {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.onboarding_content .onboarding_illustration {
    /* width: 400px; */
    display: flex;
    align-items: center;
    margin: 0 100px 0 100px;
}

.onboarding_content .onboarding_illustration img {
    /* width: 100%; */
    max-width: 600px;
    /* height: 100%; */
}

.onboarding_question {
    font-weight: 400;
    margin-bottom: 2rem;
    /* border-bottom: 1px solid var(--white); */
}

.onboarding_sub_question {
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-style: italic;
}

.onboarding_divider_wrapper {
    width: 100%;
}

.onboarding_divider_wrapper .onboarding_divider {
    width: 400px;
    border-bottom: 1px solid var(--white);
    margin: 1rem 0 4rem 0;
}

.onboarding_response {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: red; */
}


.onboarding_plan_title {
    color: var(--white);
    font-size: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 4rem;
}


.plan_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.plan_buttons .free_trial_button {
    width: fit-content;
    padding: 0.5rem 2rem;
    border: 1px solid var(--white);
    color: var(--white);
    /* background-color: var(--white); */
    /* background-color: rgba(255, 255, 255, 0.8); */
    font-size: 1.5rem;
    /* text-transform: uppercase; */
    cursor: pointer;
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.plan_buttons .free_trial_button svg {
    height: 2rem;
    fill: var(--white);
}

.plan_buttons .free_trial_button:hover {
    transform: translateX(2px)
}


.card_onboarding {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem;
    border-radius: 5px;
    margin: 0 1rem 2rem 1rem;
    min-width: 20rem;
    max-width: 90vw;
    height: fit-content;
    max-height: 80vh;
    overflow-y: auto;
    color: var(--main-color);
    transition: all 0.3s ease;
    opacity: 1;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}