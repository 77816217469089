.editor_card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem 20px;
    border-radius: 5px;
    margin: 0 0 2rem 2rem;
    width: 480px;
    height: fit-content;
    overflow-x: hidden;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

/* EDITOR MENU */

.editor_menu {
    width: 440px;
    /* background-color: var(--input-background); */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 0 2rem 0;
    position: relative;
    border-bottom: 1px solid var(--border-color);
}

.editor_menu_item {
    width: 80px;
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--light-grey);
    cursor: pointer;
}

.editor_menu_item.active {
    color: var(--twitter-blue);
    font-weight: 700;
}

.editor_menu_indicator {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 60px;
    height: 3px;
    background-color: var(--twitter-blue);
    border-radius: 5px;
    transition: 0.5s;
}

.editor_menu .editor_menu_item~.editor_menu_indicator {
    opacity: 0;
}

.editor_menu .editor_menu_item:nth-child(1).active~.editor_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*0))
}

.editor_menu .editor_menu_item:nth-child(2).active~.editor_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*1))
}

.editor_menu .editor_menu_item:nth-child(3).active~.editor_menu_indicator {
    opacity: 1;
    transform: translateX(calc(80px*2))
}

/* EDITOR COMPONENT */

.editor_component {
    width: 440px;
    opacity: 0;
    transition: 0.5s;
    height: 0px;
    overflow: hidden;
}

.editor_component.active {
    opacity: 1;
    min-height: 500px;
    height: fit-content;
}


.editor_title {
    display: flex;
    align-items: center;
    color: var(--reg-text);
    font-size: 1.3rem;
    font-weight: 500;
}

.editor_title img {
    height: 1.2rem;
    margin-right: 1rem;
}

.editor_timezones {
    width: 100%;
    margin: 0;
}

.editor_timezones>* {
    font-size: 1.1rem !important;
    color: var(--grey);
    font-weight: 500;
    border-color: var(--border-color) !important;
    outline: none !important;
    box-shadow: none !important;
}

.imagekit_upload {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

/* ACCOUNTS */

.accounts_selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.account_selection_board {
    font-size: 1.4rem;
    margin: 0 1rem;
}

.account_selection_board::before {
    content: "• ";
    color: var(--twitter-blue);
}

.select_account {
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: var(--dimmer-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.select_account svg {
    height: 3rem;
    fill: var(--light-grey);
}

.select_account:hover svg {
    fill: var(--grey);
}

.round_button {
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: var(--dimmer-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.round_button svg {
    height: 2.5rem;
    fill: var(--light-grey);
}

.round_button:hover svg {
    fill: var(--grey);
}

.mini_round_button {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: var(--dimmer-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mini_round_button svg {
    height: 1rem;
    fill: var(--light-grey);
}

.mini_round_button:hover svg {
    fill: var(--grey);
}


/* CAPTIONS */

.captions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.editor_textarea_block {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 1rem;
    position: relative;
    width: 100%;
}

/* select.editor {
    background-color: var(--white);
    padding: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    color: var(--grey);
    outline: none;
    font-size: 1.4rem;
    width: fit-content;
    -webkit-appearance: none;
    -moz-appearance: none;
} */

select.editor {
    background-color: var(--white);
    padding: 1rem 2rem 1rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    color: var(--grey);
    outline: none;
    font-size: 1.4rem;
    width: fit-content;
    cursor: pointer;
    background: url('../styles/select_arrow.svg') no-repeat 100% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
}

textarea.editor {
    padding: 0;
    /* width: 380px; */
    width: 90%;
    height: 100%;
    color: var(--grey);
    outline: none;
    border: none;
    font-size: 1.4rem;
    font-family: inherit;
    resize: none;
    overflow: auto;
    box-sizing: border-box;
    position: relative;
    background-color: var(--white);
    margin: 0 0 1rem 0;
    caret-color: var(--twitter-blue);
}

.editor_input_block {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 1rem;
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.editor_input_block svg {
    height: 2rem;
    fill: var(--light-grey);
    cursor: pointer;
}

.editor_input_block svg:hover {
    fill: var(--grey);
}

.editor_input_block_dates {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 1rem;
    position: relative;
    width: 220px;
    display: flex;
    flex-wrap: nowrap;
}

input[type="datetime-local"].editor,
input[type="datetime-local"].editor:focus,
input[type="datetime-local"].editor:active,
input[type="text"].editor,
input[type="text"].editor:focus,
input[type="text"].editor:active {
    background-color: var(--white);
    border: none;
    color: var(--grey);
    outline: none;
    font-size: 1.4rem;
    width: 100%;
    padding: 0;
    margin: 0;
    caret-color: var(--twitter-blue);
}

textarea.editor::placeholder,
input[type="text"].editor::placeholder {
    color: var(--light-grey);
}

.editor_caption_alt_icons {
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.editor_caption_alt_icons img.alternative_txt_sm_icon {
    width: 15px;
    height: 15px;
    margin: 0.2rem 0;
    filter: grayscale(100%);
    cursor: pointer;
    opacity: 0.3;
}

.editor_caption_alt_icons img.alternative_txt_sm_icon:hover {
    opacity: 0.8;
}

.editor_caption_alt_icons img.alternative_txt_sm_icon.selected {
    filter: none;
    opacity: 1;
}

.editor_caption_icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin: 1rem 0; */
}

.editor_caption_icon_block {
    display: flex;
    align-items: center;
}

.editor_caption_icon_block svg {
    cursor: pointer;
    height: 1.8rem;
    fill: var(--light-grey);
    margin: 0 0.5rem;
}

.editor_caption_icon_block svg:hover {
    fill: var(--grey);
}

.editor_caption_icon_block {
    display: flex;
    align-items: center;
}

.editor_caption_icon_block .editor_twitter_counter {
    display: flex;
    align-items: center;
    margin: 0 1rem;
}

.editor_caption_icon_block .editor_twitter_counter svg {
    height: 15px;
    margin: 0;
    padding: 0;
    fill: var(--light-grey);
}

.editor_caption_icon_block .editor_twitter_counter img {
    width: 12px;
    height: 12px;
    margin: 0;
    filter: grayscale(100%);
    cursor: pointer;
    opacity: 0.5;
}

.editor_upload_block {
    border: 1px dashed var(--border-color);
    border-radius: 5px;
    padding: 2rem 1rem;
    width: 100%;
    position: relative;
}

.editor_upload_block.active {
    border-color: var(--twitter-blue);
}

.editor_upload_block .upload_icons {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
}

.editor_upload_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.4rem;
    width: 3.4rem;
    border: 1px solid var(--border-color);
    border-radius: 1.7rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.editor_upload_icon svg {
    height: 1.8rem;
    fill: var(--light-grey);
}

.editor_upload_icon:hover {
    border-color: var(--grey);
}

.editor_upload_icon:hover svg {
    fill: var(--grey);
}


.editor_upload_medias {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.editor_media_unit {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 1rem;
}

.editor_media_unit svg {
    height: 2rem;
    width: 2rem;
    fill: var(--red);
    cursor: pointer;
    position: absolute;
    top: -1rem;
    right: -1rem;
}

.editor_media_unit img {
    max-width: 10rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.editor_media_unit video {
    max-width: 10rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}



.editor_hidden {
    opacity: 0;
    height: 0px;
}



/* VIDEO CHECKER */

.video_checker_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.video_checker {
    margin: 0.2rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.video_checker p {
    white-space: nowrap;
    margin: 0 !important;
}

.video_checker img.video_checker_sm_icon {
    height: 1.5rem;
    margin-right: 0.5rem;
}


.video_checker .video_checker_icons {
    display: flex;
    align-items: center;
}

.video_checker .video_checker_icons svg {
    height: 2rem;
    margin: 0 0.5rem;
}

/* PREVIEWS */

.card_preview {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 2rem;
    border-radius: 5px;
    margin: 0 0 2rem 2rem;
    min-width: 20rem;
    max-width: 90vw;
    height: fit-content;
    color: var(--main-color);
}

.composer_component_previews {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: calc(100vw - 800px);
    width: fit-content;
}

.composer_component .title,
.composer_title,
.composer_component_thread .title {
    color: var(--reg-text);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--light-grey);
}



/* PICKERS */


.transparent_background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9;
}

.centered_picker {
    position: fixed;
    top: 20rem;
    left: 50%;
    padding: 2rem;
    background-color: var(--white);
    z-index: 10;
    border: 1px solid var(--dim-grey);
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.library_picker {
    position: fixed;
    top: 8rem;
    left: 72rem;
    height: 600px;
    padding: 2rem;
    background-color: var(--white);
    z-index: 10;
    border: 1px solid var(--dim-grey);
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.unsplash_picker {
    position: fixed;
    top: 8rem;
    left: 72rem;
    width: 400px;
    height: 500px;
    overflow-y: auto;
    padding: 2rem;
    background-color: var(--white);
    z-index: 10;
    border: 1px solid var(--dim-grey);
    box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
    -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

/* TAGS */

.used_hashtag {
    background-color: var(--dim-grey);
    color: var(--reg-text);
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border-radius: 15px;
    text-align: center;
    width: fit-content;
    margin: 0.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    display: inline-block;
}

.used_hashtag.valid_tag {
    background-color: var(--green);
}

.used_hashtag.sensitive_tag {
    background-color: var(--yellow);
}

.used_hashtag.banned_tag {
    background-color: var(--red);
}

.used_hashtag svg {
    height: 1rem;
    margin-left: 1rem;
    cursor: pointer;
}