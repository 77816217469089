.big-sidebar {
    /* position: fixed; */
    /* left: -5px; */
    /* top: 80px; */
    width: 180px;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    height: 650px;
    background-color: var(--white);
    border-radius: 5px;
    z-index: 10001;
    padding: 1rem 0.5rem;
    -webkit-box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.2);
    /* box-shadow:
        0.3px 0.3px 1.1px -11px rgba(0, 0, 0, 0.02),
        0.7px 0.7px 2.5px -11px rgba(0, 0, 0, 0.028),
        1.3px 1.3px 4.8px -11px rgba(0, 0, 0, 0.035),
        2.2px 2.2px 8.5px -11px rgba(0, 0, 0, 0.042),
        4.2px 4.2px 15.9px -11px rgba(0, 0, 0, 0.05),
        10px 10px 38px -11px rgba(0, 0, 0, 0.07); */


}

.big-sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.big-sidebar ul li:last-child {
    margin-top: auto;
}

.big-sidebar ul li {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    color: var(--grey);
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0.2rem 0;
    width: 100%;
    cursor: pointer;
}

.big-sidebar ul li svg {
    fill: var(--grey);
    height: 2rem;
    margin-right: 0.5rem;
}

.big-sidebar ul li .main-category:hover,
.big-sidebar ul li.selected_grey .main-category {
    background-color: var(--dimmer-grey);
}

.big-sidebar ul li .main-category {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 1rem;
    letter-spacing: -0.5px;
}

.big-sidebar ul li .main-category .nav-expand {
    height: 1.8rem;
    fill: var(--grey);
    margin-left: auto;
}


.big-sidebar ul li.selected .main-category {
    padding: 1rem;
    background-image: linear-gradient(to right, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 700;
    /* line-height: 1.1; */
    transition: all 0.3s ease;
}

.big-sidebar ul li.selected .main-category svg {
    fill: var(--white);
}

.big-sidebar ul li ul {
    list-style: circle;
    margin-left: 2rem;
    height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
    margin-top: 0.2rem;
}

.big-sidebar ul li.selected ul.new_post_list {
    height: 240px;
}

.big-sidebar ul li.selected ul.calendar_list {
    height: 120px;
}

.big-sidebar ul li.selected ul.analytics_list {
    height: 80px;
}

.big-sidebar ul li.selected ul.manage_list {
    height: 200px;
}



.big-sidebar ul li ul li {
    margin: 0.2rem 0rem;
    padding: 0.8rem 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--grey);
    letter-spacing: -0.5px;
}

.big-sidebar ul li ul li svg {
    height: 1.8rem;
    fill: var(--grey);
}

.big-sidebar ul li ul li:hover {
    background-color: var(--dimmer-grey);
    color: var(--grey);
}

.big-sidebar ul li ul li:hover svg {
    fill: var(--grey);
}

.big-sidebar ul li ul li.selected_grey {
    /* background-color: var(--dimmer-grey); */
    /* background-color: var(--border-color); */
    background-color: var(--dim-grey);
    color: var(--grey);
    /* font-weight: 700; */
}


.big-sidebar ul li.latest_news {
    background-color: var(--dimmer-grey);
    /* background-color: var(--border-color); */
    border-radius: 5px;
    min-height: 100px;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.big-sidebar ul li.latest_news .title {
    font-size: 1.2rem;
    font-weight: 600;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.big-sidebar ul li.latest_news .description {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--grey);
    margin: 0.5rem 0 1rem 0;
}

.learn_more {
    font-weight: 500;
}

.learn_more::after {
    content: ' ';
    position: relative;
    top: 0px;
    width: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.90533 2.96967C8.61244 2.67678 8.13756 2.67678 7.84467 2.96967C7.55178 3.26256 7.55178 3.73744 7.84467 4.03033L11.0643 7.25H3.125C2.71079 7.25 2.375 7.58579 2.375 8C2.375 8.41421 2.71079 8.75 3.125 8.75H11.0643L7.84467 11.9697C7.55178 12.2626 7.55178 12.7374 7.84467 13.0303C8.13756 13.3232 8.61244 13.3232 8.90533 13.0303L13.4053 8.53033C13.6982 8.23744 13.6982 7.76256 13.4053 7.46967L8.90533 2.96967Z' fill='%23222C37'/%3E%3C/svg%3E%0A");
    height: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.25s ease-in-out;
}

.learn_more:hover::after {
    transform: translateX(3px);
}



.inner_menu {
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: var(--dim-grey);
    padding: 2rem 0;
    border-radius: 1rem;
    max-height: 80vh;
}

.main-category svg.sidebar_notif_dot {
    position: absolute;
    top: 8px;
    left: 23px;
    height: 10px;
    width: 10px;
    fill: var(--red);
    margin-left: auto;
}