.gmb_event_creator_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    /* background-color: var(--grey); */
    z-index: 10002;
    opacity: 0.8;
    transition: all 0.3s ease;
}

.gmb_event_creator_wrapper {
    width: fit-content;
    width: 80vw;
    height: 80vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index: 10003;
    border: 1px solid var(--grey);
    border-radius: 1rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1000ms ease;
    /* transform-origin: top right; */
    opacity: 1;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.gmb_event_creator_wrapper::-webkit-scrollbar {
    display: none;
}

.gmb_event_creator_title {
    color: var(--grey);
    font-weight: 500;
    text-align: center;
    padding-bottom: 2rem;
    width: 100%;
}

.gmb_event_creator_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.gmb_event_creator_forms {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin: 0 auto;
}

.gmb_form_options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0 0 4rem;
}