.toggle-switch-container {
  align-content: center;
}

.toggle-switch {
  margin: 0;
}

.toggle-switch {
  width: 10px;
  position: relative;
}

.toggle-switch input {
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.toggle-switch input:checked {
  z-index: 1;
}

.toggle-switch input:checked+label {
  opacity: 1;
  cursor: default;
}

.toggle-switch input:not(:checked)+label:hover {
  opacity: 0.5;
}

.toggle-switch label {
  color: var(--reg-text);
  opacity: 0.33;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.toggle-switch .toggle-outside {
  height: 100%;
  border-radius: 17px;
  padding: 11px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.toggle-switch .toggle-inside {
  border-radius: 50%;
  /* background: #424242; */
  background: var(--twitter-blue);
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.switch-vertical {
  background-color: #00F;
  width: 0px;
  height: 60px;
  margin-left: 27px;
}

.switch-vertical input {
  height: 100%;
  width: 60px;
  right: 0;
  margin: 0;
}

.switch-vertical label {
  /* font-size: 15px; */
  font-size: 1.2rem;
  line-height: 30px;
  /* display: block; */
  width: 220px;
  /* width: 100%; */
  height: 50%;
  margin: 0;
  text-align: left;
  padding-left: 12px;
  display: flex;
  align-items: center;
}

.switch-vertical .toggle-outside {
  background: var(--dim-grey);
  position: absolute;
  width: 23px;
  height: 100%;
  right: 0;
  top: 0;
}

.switch-vertical .toggle-inside {
  height: 17px;
  left: 3px;
  top: 3px;
  width: 17px;
}

.switch-vertical input:checked~.toggle-outside .toggle-inside {
  top: 3px;
}

.switch-vertical input~input:checked~.toggle-outside .toggle-inside {
  top: 40px;
}