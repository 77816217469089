.plans_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: 700;
    color: var(--reg-text);
}

.plans_title svg {
    height: 3rem;
    fill: var(--reg-text);
}

.plans_subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--reg-text);
    margin: 2rem 0;
    text-align: center;
}

.plans_options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 2rem 0;
    /* border: 1px solid var(--gradient-blue); */
    background-color: var(--dimmer-grey);
    border-radius: 10px;
    padding: 1rem 2rem;
}

.plans_options .slider {
    display: flex;
    align-items: center;
}

.plans_grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-top: 5rem; */
}

.plans_grid_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    width: 100%;
    min-height: 30px;
}

.plans_grid_row.grey {
    background-color: var(--dimmer-grey);
}

.plans_grid_description {
    width: 250px;
    display: flex;
    justify-content: flex-start;
    font-size: 1.3rem;
}

.plans_grid_description svg {
    height: 1.3rem;
    margin-right: 1rem;
}

.plans_grid_data_block {
    width: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plans_grid_data_slider {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid var(--light-grey);
    border-right: 1px solid var(--light-grey);
    padding: 0.5rem;
}

.plans_grid_data_slider .slider {
    display: flex;
    align-items: center;
}


.plans_special {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: var(--red);
}

.plans_grid_price {
    font-size: 2rem;
    color: var(--reg-text);
    font-weight: 700;
}

.plans_grid_price .currency {
    font-size: 1.2rem;
}

.plans_upgrade_button {
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: 130px;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    line-height: 1.1;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    transition: all 0.1s ease;
    border: 1px solid var(--gradient-blue);
}

.plans_upgrade_button svg {
    height: 2rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

.plans_upgrade_button:hover {
    transform: translate(2px, 0px);
}

.plans_selected_button {
    /* cursor: pointer; */
    padding: 0.5rem 1rem;
    width: 130px;
    /* background-image: linear-gradient(to top, #3D2BFF, #19A4FE); */
    color: var(--gradient-blue);
    border: 1px solid var(--gradient-blue);
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    line-height: 1.1;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    transition: all 0.1s ease;
}

.plans_selected_button svg {
    height: 2rem;
    fill: var(--gradient-blue);
    margin-right: 0.5rem;
}

.plans_grid_data.flex-text {
    font-weight: 700;
    /* color: var(--gradient-blue); */
}

.plans_grid_data svg {
    height: 2rem;
    fill: var(--green);
}

.plans_modal_wrapper {
    width: fit-content;
    /* max-width: 500px; */
    max-height: 80vh;
    overflow-y: auto;
    position: fixed;
    top: 10rem;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--white);
    z-index: 10003;
    border: 1px solid var(--dim-grey);
    border-radius: 1rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1000ms ease;
    opacity: 1;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.plans_modal_wrapper.inactive {
    opacity: 0;
}

.plans_modal_wrapper::-webkit-scrollbar {
    display: none;
}