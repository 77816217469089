/* FILTER */
.library_filter {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
}

.library_filter .library_filter_button {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--light-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 80px; */
  height: 100%;
  padding: 5px 10px;
  cursor: pointer;
}

.library_filter .library_filter_button.reg svg,
.library_filter .library_filter_button.canva svg,
.library_filter .library_filter_button.unsplash svg {
  height: 2rem;
  fill: var(--light-grey);
}

.library_filter .library_filter_button.active {
  -webkit-box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
  box-shadow: inset 0px 0px 16px -8px rgba(51, 51, 51, 0.59);
  color: var(--red);
  border: none;
}

.library_filter .library_filter_button.reg.active svg {
  fill: var(--red);
}

.library_filter .library_filter_button.canva.active svg {
  fill: #23bec7;
}

.library_filter .library_filter_button.unsplash.active svg {
  fill: #000000;
}


/* GRID */

.library-unsplash {
  height: 400px;
}

.library-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(88px, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  width: 440px;
  overflow: hidden;
  overflow-y: scroll;
}

.library_picker_media_card {
  position: relative;
  object-fit: contain;
  height: fit-content;
  width: fit-content;
  padding: 0.5rem;
  background-color: var(--card-background-color);
  border: 1px solid var(--dim-grey);
  cursor: pointer;
}

.library_picker_media_card .clipped svg {
  height: 2rem;
  width: 2rem;
  fill: var(--red);
  cursor: pointer;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}

.library_picker_selected svg {
  height: 3rem;
  width: 3rem;
  fill: var(--blue);
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
}

.library_picker_media_img {
  max-width: 8rem;
  max-height: 10rem;
}

.library_picker_media_video {
  max-width: 8rem;
  max-height: 10rem;
  overflow: hidden;
}

.library_picker_media_video video,
.library_picker_media_video img {
  width: 100%;
  object-fit: contain;
}

.library_picker_video_overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .60);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.library_picker_video_overlay svg {
  height: 3rem;
  width: 3rem;
  fill: var(--white);
  cursor: pointer;
}


.media_folder_small {
  width: 10rem;
  height: 6rem;
  margin: 0rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px dotted var(--dim-grey); */
  cursor: pointer;
  color: var(--grey);
  text-align: center;
}

.media_folder_small svg {
  height: 4rem;
  fill: var(--light-grey);
}

/* FOLDERS BLOCK */

.folders-picker-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.folders-picker-block .folder-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: red; */
}

.folders-picker-block .folder-header .team_folder {
  display: flex;
  align-items: center;
  color: var(--light-grey);
  font-weight: 700;
  font-size: 1.2rem;
  cursor: pointer;
}

.folders-picker-block .folder-header .team_folder svg {
  height: 2rem;
  fill: var(--light-grey)
}

.folders-picker-block .folder-header .team_folder:hover {
  color: var(--grey);
}

.folders-picker-block .folder-header .team_folder:hover svg {
  fill: var(--grey);
}


.folders-picker-block .current-folder {
  display: flex;
  align-items: center;
  color: var(--reg-text);
  font-weight: 700;
  font-size: 1.2rem;
}


.folders-picker-block .current-folder svg {
  height: 2rem;
  fill: var(--grey);

}

.folders-picker-block .current-folder svg.folder-up {
  margin-right: 2rem;
  cursor: pointer;
}

.folders-picker-block .folders-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
}

.folders-picker-block ul.folders-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  /* background-color: red; */
}

.folders-picker-block ul.folders-content li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 100px;
  padding: 1rem;
  cursor: pointer;
  /* background-color: red; */
  background-color: var(--white);
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--grey);
  /* border-right: 1px solid var(--dim-grey); */
}

/* .folders-picker-block ul.folders-content li:not(:last-child) {
  border-right: 1px solid var(--dim-grey);
} */

.folders-picker-block ul.folders-content li svg {
  fill: var(--grey);
  height: 2rem;
  margin-bottom: 0.5rem;
  /* margin: 2rem 0; */
}