.profile_update_bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    /* background-color: var(--grey); */
    z-index: 10002;
    opacity: 0;
    transition: all 0.3s ease;
}

.profile_update_bg.active {
    opacity: 0.8;
}

.profile_update_wrapper {
    width: fit-content;
    width: 500px;
    height: 80vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index: 10003;
    border: 1px solid var(--grey);
    border-radius: 1rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 1000ms ease;
    /* transform-origin: top right; */
    opacity: 1;
    overflow: scroll;
    scrollbar-width: none;
}

.profile_update_wrapper::-webkit-scrollbar {
    display: none;
}

.profile_update_wrapper.inactive {
    opacity: 0;
}


.profile_cropping_window {
    display: flex;
    align-items: flex-end;
}

.profile_img_cropper {
    width: 150px;
}