@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap'); */

:root {
    /* --montsert: 'Montserrat', sans-serif; */
    --montsert: 'Inter', sans-serif;
    --background-color: #eff1f5;
    /* --background-color:#F7F8FA; */
    --sidebar-background-color: #FFFFFF;
    --card-background-color: #FFFFFF;
    --input-background: #E4E6ED;
    /* --input-background: rgba(0, 0, 0, 0.1); */


    --grey: #617183;
    /* --grey: #707A84; */
    /* --light-grey: #C8CAD4; */
    --light-grey: #b7b9c3;
    --dim-grey: #e3e4e9;
    --dimmer-grey: #f5f5f5;
    --border-color: #E4E8F7;


    --white: #FFFFFF;
    --blue: #3D2BFF;
    --gradient-blue: #19A4FE;
    --blue-light: #3d2bff63;
    --dark-blue: #0B132B;
    --twitter-blue: rgb(29, 161, 242);
    /* --green:#1EFFBC; */
    --green: #5BC0BE;
    --light-green: #5bc0be28;
    --green-light: #5bc0be4d;
    --bright-green: #3dfd02;
    --pink: #F1E3F3;
    --red: #EB316F;
    --light-red: #eb316f2e;
    --red-light: #eb316f8f;
    --yellow: #fac823;
    --yellow-light: #fac82356;

    --reg-text: #222C37;

    --tooltip-text-color: #222C37;
    --tooltip-background-color: #ccd1d8;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
    --tooltip-help-margin: 10px;
}


* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    display: block;
    min-height: 100vh;
    font-size: 15px;
    font-family: var(--montsert);
    /* background-color: var(--bg-color); */
    width: 100vw;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background-color: var(--background-color);
}

.grid-container2 {
    display: grid;
    min-height: 100vh;
    height: 100%;
    grid-template-columns: 190px 1fr;
    grid-template-rows: 60px 1fr 220px;
    grid-template-areas:
        "header2 header2"
        "sidebar2 content2"
        "footer2 footer2";
}

.grid-header2 {
    grid-area: header2;
}

.grid-footer2 {
    grid-area: footer2;
}

.grid-sidebar2 {
    grid-area: sidebar2;
}



.grid-content2 {
    grid-area: content2;
    width: 100%;
    background-color: var(--background-color);
    margin: 2rem 0 0 0;
    display: flex;
    justify-content: center;
}

/* .grid-container {
    display: grid;
    min-height: 100vh;
    height: 100%;
    grid-template-columns: 75px 1fr;
    grid-template-rows: 60px 1fr;
    grid-template-areas:
        "sidebar header"
        "sidebar content";
}

.grid-sidebar {
    grid-area: sidebar;
}

.grid-header {
    grid-area: header;
}

.grid-content {
    grid-area: content;
    width: 100%;
    background-color: var(--background-color);
    margin: 2rem 0 0 0;
    display: flex;
    justify-content: center;
} */

.relative_holder {
    position: relative;
}

p {
    margin: 0.5rem;
    font-size: 1.4rem;
    letter-spacing: -0.5px;
}


p.p-no-margin {
    margin: 0;
}

p.p-small {
    font-size: 1.2rem;
}

p.p-big {
    font-size: 2rem;
}

p.p-mini {
    font-size: 1rem;
    font-weight: 500;
}

p.p-wrap {
    white-space: pre-wrap;
}

p.p-nowrap {
    text-overflow: ellipsis;
    white-space: nowrap;
}

a {
    text-decoration: none;
    color: inherit;
}

a.blue_link {
    color: var(--blue);
    font-weight: 700;
    text-decoration: none;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

a.blue_link svg {
    height: 20px;
    width: 20px;
    fill: var(--blue);
}

a.light_blue_link {
    color: var(--twitter-blue);
}

a.underline {
    text-decoration: underline;
}

.text-centered {
    text-align: center;
}

.text-grey {
    color: var(--grey);
}

.text-light-grey {
    color: var(--light-grey);
}

.text-blue {
    color: var(--twitter-blue);
}

.text-green {
    color: var(--green);
}

.text-yellow {
    color: var(--yellow) !important;
}

.text-red {
    color: var(--red) !important;
}

.text-white {
    color: var(--white);
}

.text-bold {
    font-weight: 700;
}

.text-semi-bold {
    font-weight: 500;
}

.text-italic {
    font-style: italic;
}

.text-underline {
    text-decoration: underline;
}

.clickable {
    cursor: pointer;
}

.not_found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--blue);
    font-family: var(--oops);
    font-size: 8rem;
    width: 100vw;
    height: 100vh;
}

.not_found svg {
    height: 20rem;
    width: 20rem;
    fill: var(--blue);
    margin-bottom: 5rem;
    cursor: pointer;
}

.content_row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem 0 0;
}

.content_row_third_party {
    display: flex;
    flex-grow: 1;
    padding: 0;
    margin: 8rem 0 8rem 2rem;
}

.content_row_no_margin {
    display: flex;
    flex-grow: 1;
    padding: 0;
    margin: 0;
}

.content_row_start {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    width: 100%;
    min-width: 80vw;
}

.content_row_spaced {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2rem 0;
}

.content_row_maxed {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem;
    min-width: 88vw;
    /* width: 100%; */
}

.content_row_maxed.nowrap {
    flex-wrap: nowrap;
}

.content_row_analytics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2rem;
    width: 90vw;
}

.row_title {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--reg-text);
    margin: 2rem 0;
}


.card {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem;
    border-radius: 5px;
    margin: 0 1rem 2rem 1rem;
    min-width: 20rem;
    max-width: 90vw;
    height: fit-content;
    color: var(--main-color);
    transition: all 0.3s ease;
    opacity: 1;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.card.calendar_bottom_margin {
    margin-bottom: 25rem;
}

.card_max {
    display: flex;
    flex-direction: column;
    position: relative;
    /* background-color: var(--card-background-color); */
    background-color: red;
    padding: 2rem;
    border-radius: 5px;
    margin: 0 1rem 2rem 1rem;
    width: 100%;
    height: fit-content;
    color: var(--main-color);
    transition: all 0.3s ease;
    opacity: 1;
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.card.hidden {
    opacity: 0;
}

.card_for_pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--card-background-color);
    padding: 2rem;
    border-radius: 5px;
    margin: 0 0 2rem 2rem;
    width: 54rem;
    height: 74rem;
    color: var(--main-color);
    box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07);
}

.card_for_pdf svg {
    height: 15rem;
    fill: var(--dim-grey);
}

.card .main_svg {
    height: 8rem;
}

.card .title,
.card_title {
    color: var(--dark-blue);
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    letter-spacing: -0.5px;
    flex-wrap: wrap;
}

.card .title.red {
    color: var(--red);
}


.card .big_title {
    color: var(--dark-blue);
    /* color:var(--blue); */
    font-weight: 700;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    letter-spacing: -0.5px;
    /* text-transform: capitalize; */
    flex-wrap: wrap;
}

.card .title.maxed {
    justify-content: space-between;
}

.card .title .right {
    margin-left: auto;
}

.card .mid_title {
    color: var(--dark-blue);
    letter-spacing: -0.5px;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    padding-top: 3rem;
}

.card sub {
    color: var(--grey);
    font-size: 1.2rem;
    margin-left: 1rem;
    font-style: italic;
    font-weight: 500;
}

/* .card .title svg,
.card .mid_title svg {
    fill: var(--blue);
    height: 2rem;
    width: 2rem;
    margin-right: 0.5rem;
} */

.card.bluebg {
    background: linear-gradient(0deg, var(--gradient-blue), var(--blue));
    transition: all ease 0.3s;
}

.card.bluebg .title,
.card.bluebg .big_title {
    color: var(--white);
}

.card.bluebg .title svg {
    fill: var(--white);
}

/* margins */

.margin-0 {
    margin: 0 !important;
}

.margin-t1 {
    margin-top: 1rem;
}

.margin-t2 {
    margin-top: 2rem;
}

.margin-t4 {
    margin-top: 4rem;
}

.margin-t8 {
    margin-top: 8rem;
}

.margin-b1 {
    margin-bottom: 1rem;
}

.margin-b2 {
    margin-bottom: 2rem;
}

.margin-b4 {
    margin-bottom: 4rem;
}

.margin-r1 {
    margin-right: 1rem;
}


.margin-r4 {
    margin-right: 4rem;
}

.margin-l1 {
    margin-left: 1rem;
}

.margin-l2 {
    margin-left: 2rem;
}

.margin-l4 {
    margin-left: 4rem;
}

.margin-s1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.margin-s2 {
    margin-left: 2rem;
    margin-right: 2rem;
}

.padding-0 {
    padding: 0 !important;
}

.padding-s2 {
    padding: 0 2rem;
}

.width-30 {
    max-width: 30rem;
}

.width-10 {
    width: 10rem;
}


.card .row,
.row {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}

.row.nowrap {
    display: flex;
    flex-wrap: nowrap;
    height: fit-content;
}

.row.scroll {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-wrap: nowrap;
    height: fit-content;
    white-space: nowrap;
    overflow: scroll;
    background-color: red;
}

.row.centered {
    align-items: center;
}

.row.toped {
    align-items: flex-start;
}

.row.bottomed {
    align-items: flex-end;
}

.row.relative {
    position: relative;
}

.row-100 {
    display: flex;
    width: 100%;
}

.row_spaced {
    justify-content: space-between;
}

.row_around {
    justify-content: space-around;
}

.h-100 {
    height: 100%;
}

.margin_reg {
    margin: 2rem 0;
}

.card .row_vcenter,
.row_vcenter {
    display: flex;
    align-items: center;
}

.card .row_hcenter,
.row_hcenter {
    display: flex;
    justify-content: center;
}

.row_end {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.card .column,
.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.column.centered {
    align-items: center;
}

.col-min {
    margin: 0;
    padding: 0;
}

.card .column_left,
.column_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.card .big_data {
    font-size: 4rem;
    color: var(--reg-text);
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.card .big_data .over_data {
    font-size: 2rem;
    color: var(--grey);
    font-weight: 500;
    margin-left: 1rem;
}

.row.grayscale {
    position: relative;
}

.content_title {
    padding: 4rem;
}

.grey_overlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);
}

.card_max {
    width: 100%;
}

.card_with_width_height {
    height: 90vh;
    width: 90vw;
}

.card_with_width {
    width: 84vw;
}

.card_min_width {
    min-width: 40rem;
}

.card_thread {
    width: 49rem;
}

.card_max_width {
    max-width: 50rem;
}

.card_charts {
    width: 90vw;
    height: 40rem !important;
}

.sm_button_container {
    height: 50px;
    width: 50px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    /* border-radius: 50%; */
    border: none;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.68);
}

.sm_button_container>div:first-child,
.sm_button_container a {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sm_button_container:active {
    box-shadow: none;
}

.sm_button {
    height: 40px;
    width: 40px;
    border-radius: 2px;
    /* border-radius: 20px; */
}


.border-gradient-blue {
    position: relative;
}

.border-gradient-blue::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 50%;
    padding: 5px;
    background: linear-gradient(-45deg, #743ad5, #d53a9d);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.border-gradient-green {
    position: relative;
}

.border-gradient-green::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 50%;
    padding: 5px;
    background: linear-gradient(-45deg, #3D2BFF, #B2FF59);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}


.text-red {
    color: var(--red);
}

.text-green {
    color: var(--green);
}

/* MODAL */
/* 

.modal_background{
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 100vw;
    background-color: var(--grey);
    z-index:10002;
    opacity: 0.8;
}

.modal_wrapper{
    width: fit-content;
    position: fixed;
    top:50%;
    left:50%;
    max-height: 90vh;
    max-width: 90vw;
    overflow: scroll;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    z-index:10003;
    border: 1px solid var(--grey);
    border-radius: 1rem;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); 
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.modal_wrapper::-webkit-scrollbar{
    display: none;
}

.modal_wrapper h2, .modal_wrapper h4{
    text-align: center;
}

.modal_row{
    display: flex;
}

.modal_block{
    margin:0 2rem;
}


.close_modal {
    color: var(--reg-text);
    position: absolute;
    top:0px;
    right:5px;
    font-size: 3rem;
    font-weight: 500;
  }
  
.close_modal:hover,
.close_modal:focus {
    color: var(--green);
    text-decoration: none;
    cursor: pointer;
}

.modal_title{
    color:var(--blue);
    font-weight: 700;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.modal_carousel_window{
    display: flex;
    align-items: center;
    overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width:500px;
  color:var(--reg-text);
}

.modal_carousel_slide{
    display: flex;
    flex-direction: column;
    align-items: center;

    scroll-snap-align: start;
    flex-shrink: 0;
    width: 500px;
    margin-right: 5rem;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;   
}

.modal_carousel_slide svg{
    height: 15rem;
}

.modal_carousel_slide img{
    max-width: 500px;
    max-height: 300px;
}

.modal_wrapper .dots{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 3rem 0;
}

.modal_wrapper .dont_show{
    display: flex;
    align-items: center;
}

.modal_wrapper .dots a.dot{
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 0.7rem;
    background-color: var(--grey);
    margin: 0 1rem;
}

.modal_wrapper .dots a.dot.active{
    background-color: var(--blue);
} */



/* END MODAL */


.account_profile_photo {
    position: relative;
    height: 40px;
    width: 50px;
}

.account_profile_ai {
    height: 40px;
    width: 40px;
    background-color: var(--white);
    border-radius: 20px;
    border: 1px solid var(--light-grey);
    display: flex;
    justify-content: center;
    align-items: center;
}

.account_profile_ai svg {
    height: 30px;
    fill: var(--light-grey);
}

.account_profile_photo.unselected {
    filter: grayscale(100%) opacity(80%);
    cursor: pointer;
}

.account_profile_photo.selected {
    cursor: pointer;
}

.account_profile_media {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--white);
    border: 1px solid var(--white);
    padding: 2px;
}

.profile_picture {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 1px solid var(--light-grey);
}

.default_fb_group {
    height: 40px;
    width: 40px;
    background-image: url("../../images/social_media/fb-group.png");
}

.svg-1-5 {
    height: 1.5rem;
    width: 1.5rem;
}

.svg-2 {
    height: 2rem;
    width: 2rem;
}

.svg-4 {
    height: 4rem;
    width: 4rem;
}

.svg-margin-lr2 {
    margin: 0 2rem;
}

.svg-margin-t1 {
    margin-top: 1rem;
}

.svg-red {
    fill: var(--red) !important;
}

.svg-blue {
    fill: var(--blue);
}

.svg-dark-blue {
    fill: var(--dark-blue) !important;
}

.svg-green {
    fill: var(--green) !important;
}

.svg-bright-green {
    fill: var(--bright-green) !important;
}

.svg-yellow {
    fill: var(--yellow) !important;
}

.svg-grey {
    fill: var(--grey) !important;
}

.svg-light-grey {
    fill: var(--light-grey) !important;
}

.svg-white {
    fill: var(--white) !important;
}

.svg-action {
    cursor: pointer;
    filter: opacity(50%);
}

.svg-action:hover {
    filter: opacity(100%);
}

.svg-big {
    height: 15rem;
}

.svg-mid {
    height: 8rem;
}

.img-20 {
    max-width: 20rem;
}

.img-100px {
    max-width: 100px;
}

.img-fluid {
    width: 100%;
}

.img-h10 {
    max-height: 10rem;
}


.light_button {
    display: flex;
    align-items: center;
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;
}

.light_button.small {
    padding: 0.5rem;
    font-size: 0.9rem;
}

.light_button svg {
    height: 1.4rem;
    width: 1.4rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

.light_button:hover {
    color: var(--blue);
    background-color: transparent;
}

.light_button:hover svg {
    fill: var(--blue);
}

.mini_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dim-grey);
    border: 1px solid var(--light-grey);
    padding: 0.5rem;
    outline: none;
    width: fit-content;
    border-radius: 2px;
    cursor: default;
    margin: 0 1rem;
    font-size: 1.2rem;
    color: var(--reg-text);
}

.mini_button.active {
    cursor: pointer;
}

.mini_button.active svg {
    fill: var(--green);
}

.mini_button svg {
    height: 1.4rem;
    fill: var(--grey);
    margin: 0 0.5rem;
}

.column_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 1rem;
    cursor: pointer;
    margin-right: 1rem;
    width: 100%;
}

.column_button.small {
    padding: 0.5rem;
    font-size: 0.9rem;
}

.column_button svg {
    height: 1.4rem;
    width: 1.4rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

.column_button:hover {
    color: var(--blue);
    background-color: transparent;
}

.column_button:hover svg {
    fill: var(--blue);
}

.sub-button {
    font-weight: 500;
    font-size: 1.1rem;
}

.nav_button {
    cursor: pointer;
    height: 40px;
    padding: 0.8rem 1.2rem;
    background-color: var(--dim-grey);
    color: var(--grey);
    font-weight: 700;
    font-size: 1.5rem;
    border-radius: 20px;
    display: flex;
    line-height: 1.1;
    align-items: center;
    letter-spacing: -1px;
    transition: all 0.1s ease;
}

.nav_button:hover {
    /* transform: translate(2px, 0px); */
    /* transform: scale(1.02); */
    background-color: var(--light-grey);
}

.nav_button svg {
    height: 2rem;
    width: 2rem;
    fill: var(--grey);
    margin: 0 0.5rem;
}

.action_button {
    cursor: pointer;
    padding: 0.8rem 1.2rem;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    line-height: 1.1;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    transition: all 0.1s ease;
}

.action_button.red {
    background-image: linear-gradient(to top, #df0022, #fd4a65);
}

.action_button:hover {
    transform: translate(2px, 0px);
    /* transform: scale(1.02); */
}

.action_button svg {
    height: 2rem;
    width: 2rem;
    fill: var(--white);
    margin: 0 0.5rem;
}

.action_button_outline {
    cursor: pointer;
    padding: 0.8rem 1.2rem;
    color: var(--gradient-blue);
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid var(--gradient-blue);
    display: flex;
    line-height: 1.1;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    transition: all 0.1s ease;
}

.action_button_outline.red {
    border: 1px solid var(--red);
    color: var(--red);
}


.action_button_outline:hover {
    transform: translate(2px, 0px);
    /* transform: scale(1.02); */
}

.action_button_outline svg {
    height: 2rem;
    width: 2rem;
    fill: var(--gradient-blue);
    margin: 0 0.5rem;
}


.big_action_button {
    width: 320px;
    height: 120px;
    cursor: pointer;
    padding: 0.8rem 1.2rem;
    background-image: linear-gradient(to top, #3D2BFF, #19A4FE);
    color: var(--white);
    font-weight: 500;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1.1;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;
    transition: all 0.1s ease;
    /* box-shadow:
        1.3px 1.4px 2.2px rgba(0, 0, 0, 0.02),
        3.1px 3.4px 5.3px rgba(0, 0, 0, 0.028),
        5.8px 6.4px 10px rgba(0, 0, 0, 0.035),
        10.3px 11.4px 17.9px rgba(0, 0, 0, 0.042),
        19.2px 21.3px 33.4px rgba(0, 0, 0, 0.05),
        46px 51px 80px rgba(0, 0, 0, 0.07); */
}

.big_action_button:hover {
    transform: translate(3px, -3px);
    /* transform: scale(1.02); */
}

.big_action_button svg {
    height: 4rem;
    width: 4rem;
    fill: var(--white);
    margin: 0.5rem;
}

.big_action_button .main_title {
    font-size: 2rem;
    text-align: center;
    margin: 0.5rem
}

.big_action_button .sub_title {
    font-size: 1.5rem;
    text-align: center;
}

.big_action_button .mini_title {
    font-size: 1.2rem;
    text-align: center;
}


.green_button {
    display: flex;
    align-items: center;
    background-color: var(--green);
    border: 1px solid var(--green);
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;
    line-height: 1;
}

.green_button.small {
    padding: 0.5rem;
    font-size: 0.9rem;
}

.green_button svg {
    height: 2rem;
    width: 2rem;
    fill: var(--white);
    margin: 0 0.5rem;
}

.green_button:hover {
    color: var(--green);
    background-color: transparent;
}

.green_button:hover svg {
    fill: var(--green);
}

.red_button {
    display: flex;
    align-items: center;
    background-color: var(--red);
    border: 1px solid var(--red);
    color: var(--white);
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    outline: none;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 1rem;
    line-height: 1;
}

.red_button svg {
    height: 1.4rem;
    width: 1.4rem;
    fill: var(--white);
    margin-right: 0.5rem;
}

.red_button:hover {
    color: var(--red);
    background-color: transparent;
}

.red_button:hover svg {
    fill: var(--red);
}

.sensitive_button {
    display: flex;
    align-items: center;
    background-color: var(--yellow);
    border: 1px solid var(--yellow);
    color: var(--reg-text);
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    outline: none;
    width: fit-content;
    border-radius: 1rem;
    cursor: pointer;
    margin-right: 1rem;
}

.sensitive_button svg {
    height: 1.4rem;
    width: 1.4rem;
    fill: var(--reg-text);
    margin-right: 0.5rem;
}

.sensitive_button:hover {
    color: var(--reg-text);
    background-color: transparent;
}

.sensitive_button:hover svg {
    fill: var(--reg-text);
}

.profile_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--blue);
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--white);
}

.profile_icon img {
    width: 40px;
}

.profile_icon svg {
    fill: var(--white);
    height: 40px;
    width: 40px;
}

.divider {
    width: 10rem;
    border-top: 1px solid var(--light-grey);
    margin: 1rem 0;
}

.divider_white {
    width: 10rem;
    border-top: 1px dotted var(--white);
    margin: 1rem 0;
}

.divider-50 {
    width: 50%;
    border-top: 1px solid var(--light-grey);
    margin: 1rem 0;
}

.divider-100 {
    width: 100%;
    border-top: 1px solid var(--light-grey);
    margin: 1rem 0;
}

.v-divider {
    width: 1px;
    height: 1rem;
    border-right: 1px solid var(--light-grey);
    margin: 0;
}

.v-divider-max {
    width: 1px;
    /* height: 100%; */
    border-right: 1px dotted var(--light-grey);
    /* margin: 0; */
}

.dots,
.dots-blue {
    cursor: pointer;
}

.dots svg {
    height: 1.8rem;
    fill: var(--light-grey) !important;
}

.dots svg:hover {
    fill: var(--grey) !important;
}

.dots-blue svg {
    height: 1.8rem;
    fill: var(--blue) !important;
    opacity: 0.6;
}

.dots-blue svg:hover {
    opacity: 1;
}

.bullet-point {
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dim-grey);
    margin-right: 1rem;
}

.bullet-point svg {
    height: 2rem;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.column-flex-start {
    align-items: flex-start !important;
    width: 100%;
}

.info-line {
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    border-left: 3px solid var(--yellow);
    padding-left: 0.5rem;
}

.info-line ul {
    margin: 0;
    padding-left: 3rem;
    line-height: 1.5;
}

.dotted-bottom {
    border-bottom: 1px dotted var(--grey);
}


/* POST PREVIEW COMMONS */

.sm-badge-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--white);
    border: 1px solid var(--dim-grey);
    /* box-shadow: 1px 1px 10px 1px rgba(34,44,55,0.5);
    -webkit-box-shadow: 1px 1px 10px 1px rgba(34,44,55,0.5);
    -moz-box-shadow: 1px 1px 10px 1px rgba(34,44,55,0.5); */
}

.sm-badge-wrapper img {
    width: 100%;
    /* opacity: 0.8; */
    /* border-radius: 15px; */
}


/* FEED GRID */

.feed_grid {
    display: grid;
    /* margin-bottom: 8rem; */
    grid-template-columns: 5px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        "timeline feed";
}

.feed_grid_timeline {
    grid-area: timeline;
    border-left: 5px solid var(--dim-grey);
}

.feed_grid_feed {
    grid-area: feed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.update_button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    margin-left: 1rem;
}

.update_button svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: var(--white);
}


.lock_svg svg {
    height: 1.5rem;
    margin-left: 1rem;
    fill: var(--grey);
}


@media screen and (max-width: 768px) {
    .grid-container {
        display: grid;
        min-height: 100vh;
        height: 100%;
        margin-bottom: 8rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 40px;
        grid-template-areas:
            "content"
            "sidebar";
    }

    .grid-sidebar {
        grid-area: sidebar;
    }

    .content_row_analytics {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        width: 100vw;
    }
}