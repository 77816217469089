.preview_card {
    background-color: white;
    width: 300px;
    border: 1px solid #d0d1d5;
    border-radius: 3px;
    margin: 1rem;
    padding: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .30);
    position: relative;
}

.preview_card.preview_card_draft {
    background: repeating-linear-gradient(45deg,
            var(--dim-grey),
            var(--dim-grey) 10px,
            var(--dimmer-grey) 10px,
            var(--dimmer-grey) 20px);
}

.preview_card.ig_preview {
    padding: 0;
}

/* HEADER */

.preview_card .header {
    margin-bottom: 1.5rem;
}

.preview_card .header.gmb {
    padding: 1rem;
}

.preview_card .header .co-logo {
    float: left;
    margin-right: 8px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
}

.preview_card .header .co-name {
    font-family: Helvetica;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.2;
    color: #365899;
    text-decoration: none;
    margin-bottom: 2px;
}


.preview_card .header .time {
    font-family: Helvetica;
    font-size: 1rem;
    color: #90949c;
}

/* TEXT */


.preview_card .content {
    clear: both;
    font-family: Helvetica, sans-serif;
    font-size: 1.2rem;
    line-height: 1.38;
}

.preview_card .content.ig_content {
    padding: 1rem;
}

.preview_card .content .text {
    white-space: pre-wrap;
}

.preview_card .content .tags {
    color: hsla(214, 89%, 52%, 1);
}

/* LINK PREVIEW */

.preview_card .link_preview {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
}

.preview_card .link_preview .link_thumb {
    width: 100%;
    display: block;
}

.preview_card .link_preview .link_title {
    margin-top: 0.5rem;
    padding: 0 0.4rem;
    font-family: Helvetica;
    color: #050505;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.4rem;
    font-weight: 600;
    margin-bottom: 5px;
    -webkit-font-smoothing: antialiased;
}

.preview_card .link_preview .link_subtitle {
    font-family: Helvetica;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #65676b;
    padding: 0 0.4rem;
}

/* MEDIA */

.preview_card .media_container {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    /* max-width: 500px; */
    width: 280px;
    overflow: hidden;
    flex-wrap: wrap;
}

.preview_card .media_wrapper {
    flex: 1;
    min-width: 50%;
    min-height: 50%;
    position: relative;
}

.preview_card .media_wrapper img,
.preview_card .media_wrapper video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 2px;
}

.preview_card .media_wrapper .media_wrapper_overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--white);
    font-size: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview_card .media_wrapper .video_overlay,
.preview_card .tw_media_wrapper .video_overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .60);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview_card .media_wrapper .video_overlay svg,
.preview_card .tw_media_wrapper .video_overlay svg {
    height: 8rem;
    width: 8rem;
    fill: var(--white);
}


.preview_card .media_wrapper .video_icon,
.preview_card .tw_media_wrapper .video_icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 8rem;
    width: 100%;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    padding: 1rem;
}


.preview_card .media_wrapper .video_icon svg,
.preview_card .tw_media_wrapper .video_icon svg {
    height: 3rem;
    width: 3rem;
    fill: var(--white);
}

/* SOCIAL BUTTONS */

.preview_card .preview_social {
    margin-top: 12px;
}

.preview_card .preview_social .social-buttons {
    color: #7f7f7f;
    font-family: Helvetica;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2rem;
    border-top: 1px solid #e5e5e5;
    padding-top: 4px;

}

.preview_card .preview_social .social-buttons span {
    font-size: 1rem;
    margin-right: 20px;
    padding: 4px 4px 4px 0;
}

.preview_card .preview_social .social-buttons span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.preview_card .preview_social .social-buttons span i {
    padding-right: 4px;
}



.preview_card .ui_icons_tk {
    position: absolute;
    right: 5px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview_card .ui_icons_yt {
    position: absolute;
    right: 5px;
    bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview_card .ui_icons_profile {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    border: 2px solid var(--white);
    overflow: hidden;
}

.preview_card .ui_icons_tk .ui_icons {
    width: 40px;
    filter: drop-shadow(0px 0px 2px #000000);
}

.preview_card .ui_icons_yt .ui_icons {
    width: 40px;
    filter: drop-shadow(0px 0px 2px #000000);
}

.tk_video_social_name {
    position: absolute;
    left: 10px;
    bottom: 85px;
    color: var(--white);
    font-weight: 700;
    font-size: 1.2rem;
    width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tk_video_text {
    position: absolute;
    left: 10px;
    bottom: 50px;
    color: var(--white);
    font-weight: 500;
    font-size: 1.1rem;
    width: 230px;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.yt_video_social_name {
    position: absolute;
    left: 10px;
    bottom: 65px;
    color: var(--white);
    font-weight: 700;
    font-size: 1.2rem;
    width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.preview_card .yt_video_social_name .yt_ui_icons_profile {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    /* border: 2px solid var(--white); */
    overflow: hidden;
    margin-right: 0.5rem;
}

.yt_video_text {
    position: absolute;
    left: 10px;
    bottom: 30px;
    color: var(--white);
    font-weight: 500;
    font-size: 1.1rem;
    width: 230px;
    height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* ///////////////////////////// BLANK CARD SPECIFiCS*/

.co-logo-mockup {
    float: left;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    margin-bottom: 1rem;
}

.co-name-mockup {
    display: block;
    width: 100px;
    height: 15px;
    background-color: var(--dim-grey);
    margin-bottom: 2px;
    margin-left: 48px;
}

.time-mockup {
    display: block;
    width: 50px;
    height: 12px;
    background-color: var(--dim-grey);
    margin-left: 48px;
}

.text-mockup {
    width: 100%;
    height: 100px;
    background-color: var(--dim-grey);
}

.skeleton-box {
    position: relative;
    overflow: hidden;
    background-color: var(--dim-grey);
}

.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    -webkit-animation: shimmer 2s infinite;
    animation: shimmer 2s infinite;
    content: "";
}

@-webkit-keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}


/* ///////////////////////////// IG CARD SPECIFiCS*/

.preview_card .ig_header {
    padding: 14px 4px 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.preview_card .ig_header .header_block {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: rgba(var(--f75, 38, 38, 38), 1);
    font-weight: 700;

}

.preview_card .ig_header .header_block .ig_icon {
    width: 3rem;
    margin-right: 1rem;
    border-radius: 1.5rem;
}

.preview_card .ig_header .header_block svg {
    width: 2rem;
    margin-right: 1rem;
    fill: var(--reg-text);
}

.ig_photo_container {
    max-width: 300px;
    position: relative;
}

.ig_photo_container img {
    width: 100%;
}

.ig_photo_container .photo_tag {
    position: absolute;
    background-color: var(--reg-text);
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    cursor: default;
    display: flex;
    align-items: center;
}

.ig_photo_container .photo_tag svg {
    height: 1.2rem;
    fill: var(--white);
    margin-right: 0.5rem;
}


.ig_photo {
    max-width: 300px;
}


.ig_icons {
    width: 100%;
    padding: 0.5rem;
}

.ig_likes {
    padding: 1rem;
    font-size: 1.2rem;
    color: rgba(var(--f75, 38, 38, 38), 1);
    font-weight: 600;
}

.content-ig {
    font-family: Helvetica;
    padding: 1rem;
}

.ig_tags {
    color: #00376b;
    margin: 1rem 0;
}

.classic_tags {
    color: #3b94d9;
    margin: 1rem 0;
}

/* ///////////////////////////// LK CARD SPECIFiCS*/


.preview_card .header .co-name-lk {
    font-family: Helvetica;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.2;
    color: rgba(var(--f75, 38, 38, 38), 1);
    text-decoration: none;
    margin-bottom: 2px;
}

.preview_card .header .time-lk {
    font-family: Helvetica;
    font-size: 1rem;
    color: rgba(var(--f75, 38, 38, 38), 1);
}

.lk_media_container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    width: 280px;
    height: 280px;
    overflow: hidden;
    flex-wrap: wrap;
}

.lk_media_wrapper {
    flex: 1;
    min-width: 33%;
    position: relative;
}

.lk_media_wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 1px;
}

.lk_icons {
    width: 100%;
}

.lk_poll_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    border: 1px solid var(--dim-grey);
    border-radius: 5px;
    width: 100%;
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.lk_poll_block.cal_block {
    font-size: 1rem;
}

.lk_poll_block.grid_block {
    font-size: 1rem;
    width: 150px;
    height: 150px;
    overflow-y: auto;
}

.lk_poll_block .lk_poll_question {
    color: var(--reg-text);
    margin-bottom: 1rem;
}

.lk_poll_block .lk_poll_choice_item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    border: 1px solid var(--twitter-blue);
    border-radius: 15px;
    margin: 0.5rem 0;
    text-align: center;
    color: var(--twitter-blue);
}

/* ///////////////////////////// TW CARD SPECIFiCS*/

.preview_card .header .co-name-tw {
    font-family: Helvetica;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.2;
    color: #000;
    text-decoration: none;
    margin-bottom: 2px;
}

.preview_card .content .tw_tags {
    color: #3b94d9;
}

.preview_card .link_preview.link_preview_tw {
    border-radius: 10px;
    overflow: hidden;
}

.tw_media_container {
    display: flex;
    justify-content: space-around;
    margin: 20px auto 0px auto;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
    flex-wrap: wrap;
    border: 1px solid var(--dim-grey);
    border-radius: 10px;
}

.tw_media_wrapper {
    flex: 1;
    min-width: 50%;
    min-height: 50%;
    position: relative;
}

.tw_media_wrapper img,
.tw_media_wrapper video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* padding: 2px; */
}

.tw_footer {
    display: flex;
    margin: 1rem;
}

.tw_footer>* {
    margin: 0 2rem;
}

.tw_icon_verified {
    opacity: 1;
    cursor: default;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCA3MiI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGg2NHY3MkgweiIvPjxwYXRoIGZpbGw9IiM4OGM5ZjkiIGQ9Ik0zIDM3LjMxNWMwIDQuMTI1IDIuMTYyIDcuNzI2IDUuMzYzIDkuNjI0LS4wNTYuNDY3LS4wOS45MzctLjA5IDEuNDIgMCA2LjEwMyA0LjcyIDExLjA0NSAxMC41NDYgMTEuMDQ1IDEuMjk1IDAgMi41NDItLjIzNCAzLjY4Ny0uNjg2QzI0LjIyIDYyLjQgMjcuODI3IDY0LjkzIDMyIDY0LjkzYzQuMTc0IDAgNy43ODItMi41MyA5LjQ5LTYuMjEzIDEuMTQ4LjQ1IDIuMzkuNjg1IDMuNjkuNjg1IDUuODI2IDAgMTAuNTQ2LTQuOTQgMTAuNTQ2LTExLjA0NSAwLS40ODMtLjAzNy0uOTUzLS4wOTMtMS40MkM1OC44MyA0NS4wNCA2MSA0MS40NCA2MSAzNy4zMTRjMC00LjM3LTIuNDItOC4xNS01LjkzMy05Ljk0Ni40MjctMS4yMDMuNjU4LTIuNS42NTgtMy44NjUgMC02LjEwNC00LjcyLTExLjA0NS0xMC41NDUtMTEuMDQ1LTEuMzAyIDAtMi41NDMuMjMyLTMuNjkuNjg4LTEuNzA3LTMuNjg1LTUuMzE1LTYuMjE2LTkuNDktNi4yMTYtNC4xNzMgMC03Ljc3OCAyLjUzLTkuNDkyIDYuMjE2LTEuMTQ2LS40NTUtMi4zOTMtLjY4OC0zLjY4OC0uNjg4LTUuODI3IDAtMTAuNTQ1IDQuOTQtMTAuNTQ1IDExLjA0NSAwIDEuMzY0LjIzIDIuNjYyLjY1NiAzLjg2NEM1LjQyIDI5LjE2MyAzIDMyLjk0NCAzIDM3LjMxNHoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTcuODcgMzkuMDhsNy4wMTUgNi45NzhjLjU4NS41ODIgMS4zNS44NzMgMi4xMTYuODczLjc3IDAgMS41NDItLjI5NCAyLjEyNy0uODgzLjM0NC0uMzQ2IDE1Ljk4LTE1Ljk3NCAxNS45OC0xNS45NzQgMS4xNzItMS4xNzIgMS4xNzItMy4wNyAwLTQuMjQzLTEuMTctMS4xNy0zLjA3LTEuMTcyLTQuMjQyIDBsLTEzLjg3IDEzLjg2My00Ljg5Mi00Ljg2OGMtMS4xNzQtMS4xNjgtMy4wNzQtMS4xNjQtNC4yNDIuMDEtMS4xNjggMS4xNzYtMS4xNjMgMy4wNzUuMDEgNC4yNDR6Ii8+PC9zdmc+');
}

.tw_icon_heart {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCA3MiI+PHBhdGggY2xhc3M9Imljb24iIGZpbGw9IiM4ODk5YTYiIGQ9Ik0zOC43MjMgMTJjLTcuMTg3IDAtMTEuMTYgNy4zMDYtMTEuNzIzIDguMTMtLjU2My0uODI0LTQuNDk2LTguMTMtMTEuNzIzLTguMTNDOC43OSAxMiAzLjUzMyAxOC4xNjMgMy41MzMgMjQuNjQ3IDMuNTMzIDM5Ljk2NCAyMS44OSA1NS45MDcgMjcgNTZjNS4xMS0uMDkzIDIzLjQ2Ny0xNi4wMzYgMjMuNDY3LTMxLjM1M0M1MC40NjcgMTguMTYzIDQ1LjIxIDEyIDM4LjcyMyAxMnoiLz48L3N2Zz4=');
}

.tw_icon_heart:hover {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCA3MiI+PHBhdGggY2xhc3M9Imljb24iIGZpbGw9IiNlODFjNGYiIGQ9Ik0zOC43MjMgMTJjLTcuMTg3IDAtMTEuMTYgNy4zMDYtMTEuNzIzIDguMTMtLjU2My0uODI0LTQuNDk2LTguMTMtMTEuNzIzLTguMTNDOC43OSAxMiAzLjUzMyAxOC4xNjMgMy41MzMgMjQuNjQ3IDMuNTMzIDM5Ljk2NCAyMS44OSA1NS45MDcgMjcgNTZjNS4xMS0uMDkzIDIzLjQ2Ny0xNi4wMzYgMjMuNDY3LTMxLjM1M0M1MC40NjcgMTguMTYzIDQ1LjIxIDEyIDM4LjcyMyAxMnoiLz48L3N2Zz4=');
}

.tw_icon_share {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1Ni44IDcyIj48cGF0aCBmaWxsPSJub25lIiBkPSJNMC0uN2g0OHY3MkgweiIvPjxwYXRoIGNsYXNzPSJpY29uIiBmaWxsPSIjNjY3NTgwIiBkPSJNNTIuOCAzMi45TDQyLjIgMjIuM2MtMS40LTEuNC0zLjYtMS40LTUgMHMtMS40IDMuNiAwIDVsNC42IDQuNkgyMC45Yy0xLjkgMC0zLjUgMS42LTMuNSAzLjVzMS42IDMuNSAzLjUgMy41aDIwLjlsLTQuNiA0LjZjLTEuNCAxLjQtMS40IDMuNiAwIDUgLjcuNyAxLjYgMSAyLjUgMXMxLjgtLjMgMi41LTFsMTAuNi0xMC42YzEuNC0xLjQgMS40LTMuNiAwLTV6Ii8+PHBhdGggY2xhc3M9Imljb24iIGZpbGw9IiM2Njc1ODAiIGQ9Ik0yNC40IDQ5LjRoLTEzYy0uNiAwLTEuMS0uNS0xLjItMS4xVjIyLjRjMC0uNy41LTEuMiAxLjItMS4yaDEyLjljMS45IDAgMy41LTEuNiAzLjUtMy41cy0xLjYtMy41LTMuNS0zLjVIOC45Yy0zLjEgMC01LjcgMi41LTUuNyA1Ljd2MzFjMCAzLjEgMi41IDUuNyA1LjcgNS43aDE1LjVjMS45IDAgMy41LTEuNiAzLjUtMy41LjEtMi4xLTEuNS0zLjctMy41LTMuN3oiLz48L3N2Zz4=');
}

.tw_icon_share:hover {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1Ni44IDcyIj48cGF0aCBmaWxsPSJub25lIiBkPSJNMC0uN2g0OHY3MkgweiIvPjxwYXRoIGNsYXNzPSJpY29uIiBmaWxsPSIjMDA4NGI0IiBkPSJNNTIuOCAzMi45TDQyLjIgMjIuM2MtMS40LTEuNC0zLjYtMS40LTUgMHMtMS40IDMuNiAwIDVsNC42IDQuNkgyMC45Yy0xLjkgMC0zLjUgMS42LTMuNSAzLjVzMS42IDMuNSAzLjUgMy41aDIwLjlsLTQuNiA0LjZjLTEuNCAxLjQtMS40IDMuNiAwIDUgLjcuNyAxLjYgMSAyLjUgMXMxLjgtLjMgMi41LTFsMTAuNi0xMC42YzEuNC0xLjQgMS40LTMuNiAwLTV6Ii8+PHBhdGggY2xhc3M9Imljb24iIGZpbGw9IiMwMDg0YjQiIGQ9Ik0yNC40IDQ5LjRoLTEzYy0uNiAwLTEuMS0uNS0xLjItMS4xVjIyLjRjMC0uNy41LTEuMiAxLjItMS4yaDEyLjljMS45IDAgMy41LTEuNiAzLjUtMy41cy0xLjYtMy41LTMuNS0zLjVIOC45Yy0zLjEgMC01LjcgMi41LTUuNyA1Ljd2MzFjMCAzLjEgMi41IDUuNyA1LjcgNS43aDE1LjVjMS45IDAgMy41LTEuNiAzLjUtMy41LjEtMi4xLTEuNS0zLjctMy41LTMuN3oiLz48L3N2Zz4=');
}

/* ///////////////////////////// GMB CARD SPECIFiCS*/

.preview_card .header .co-name-gmb {
    font-family: Helvetica;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
    color: rgba(var(--f75, 38, 38, 38), 1);
    text-decoration: none;
    margin-bottom: 2px;
}

.gmb_media_container {
    display: flex;
    justify-content: space-around;
    margin-top: 0px;
    width: 298px;
    height: 250px;
    overflow: hidden;
    flex-wrap: wrap;
    background-color: black;
}


.gmb_media_wrapper {
    flex: 1;
    width: 100%;
    position: relative;
    width: 298px;
    height: 250px;
    display: flex;
    justify-content: center;
}

.gmb_media_wrapper img {
    width: 100%;
    height: 100%;
    padding: 1px;
    object-fit: contain;
}


.gmb_content {
    margin: 2rem 0;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
}

.gmb_event_wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.gmb_event_wrapper .gmb_event_offer {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 3rem;
    width: 3rem;
}

.gmb_event_wrapper .gmb_event_offer svg {
    height: 2rem;
    fill: rgb(221, 168, 36);
}

.gmb_event_wrapper .gmb_event_title {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--reg-text);
    letter-spacing: -1px;
}

.gmb_event_wrapper .gmb_event_dates {
    color: var(--reg-text);
    letter-spacing: -1px;
    margin-top: 0.5rem;
}

.gmb_content .gmb_event_wrapper .gmb_text {
    margin-top: 1rem;
    color: var(--grey);
    letter-spacing: -0.2px;
}

.gmb_content .gmb_text {
    color: var(--reg-text);
    letter-spacing: -0.2px;
}

.gmb_cta {
    color: #4C8BF5;
    font-size: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;
}



/* FIRST COMMENT */

.preview_first_comment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    word-wrap: normal;
    padding: 1rem 2rem;
}

.preview_first_comment svg {
    height: 1.5rem;
    min-width: 1.5rem;
    fill: var(--grey);
    margin-right: 0.2rem;
}