/* FONT IMPORTS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --cal-main-color: #1a8fff;
  --cal-text-color: #777;
  --cal-text-color-light: #ccc;
  --cal-border-color: #eee;
  --cal-bg-color: #f9f9f9;
  --cal-neutral-color: #fff;
}

/* ACCOUNT PICKER */

.cal_accounts_picker {
  position: absolute;
  top: 8rem;
  left: 50rem;
  right: 20rem;
  height: 200px;
  overflow-y: auto;
  padding: 2rem;
  background-color: var(--white);
  z-index: 100;
  border: 1px solid var(--dim-grey);
  box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
  -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
  -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.cal_accounts_picker.cal_tz_picker {
  right: auto;
  width: 400px;
  height: 450px;
}

.timezone_wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  width: fit-content;
  cursor: pointer;
  flex-wrap: nowrap;
}

.timezone_wrapper p {
  white-space: nowrap;
}

.timezone_wrapper svg {
  fill: var(--grey);
  height: 2rem;
}

/* GRID */

.cal-row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.cal-header-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cal-legend {
  display: flex;
  justify-content: flex-start;
  /* width: 100%; */
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 0 0.5rem;
  position: relative;
}

.cal-legend-overlay {
  position: absolute;
  top: 0px;
  left: 100px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;
  visibility: hidden;
  z-index: 100;
  border: 1px solid var(--dim-grey);
  box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
  -webkit-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
  -moz-box-shadow: 1px 1px 20px 1px rgba(34, 44, 55, 0.75);
}

.cal-legend-overlay.visible {
  visibility: visible;
}

.cal-legend-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--grey);
  font-style: italic;
  letter-spacing: -0.5px;
  margin: 1rem 0rem;
  text-transform: none;
  font-weight: 500;
}

.cal-overlay-legend-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--grey);
  font-style: italic;
  letter-spacing: -0.5px;
  margin: 1rem 0rem;
  text-transform: none;
  font-weight: 500;
  white-space: nowrap;
}

.cal-overlay-legend-item svg {
  margin-right: 1rem;
}

.cal-overlay-legend-item .legend-draft {
  width: 60px;
  height: 20px;
  background: repeating-linear-gradient(45deg,
      var(--dim-grey),
      var(--dim-grey) 10px,
      var(--dimmer-grey) 10px,
      var(--dimmer-grey) 20px);
  border: 1px solid var(--grey);
  border-radius: 3px;
  margin-right: 0.5rem;
}

.cal-row-middle {
  align-items: center;
}

.cal-col,
.cal-col-weekly {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.cal-col-start {
  justify-content: flex-start;
  text-align: left;
}

.cal-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cal-col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--cal-neutral-color);
  /* border: 1px solid var(--cal-border-color); */
  /* overflow: hidden; */
}

.calendar .cal-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--cal-border-color);
}

.calendar .cal-header .cal-icon {
  cursor: pointer;
  transition: .15s ease-out;
  margin: 0 1rem;
}

.calendar .cal-header .cal-icon svg {
  height: 2rem;
}

.calendar .cal-header .cal-icon:hover {
  transition: .25s ease-out;
  color: var(--blue);
}

.calendar .cal-header .cal-icon:first-of-type {
  margin-left: 1em;
}

.calendar .cal-header .cal-icon:last-of-type {
  margin-right: 1em;
}

.calendar .cal-days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--grey);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--cal-border-color);
}

.calendar .cal-body .cal-cell {
  position: relative;
  /* min-height:5em; */
  height: 15em;
  /* border-right: 1px solid var(--cal-border-color); */
  overflow: hidden;
  /* scroll-behavior: smooth; */
  /* cursor: pointer; */
  /* background: var(--cal-neutral-color); */
  background: #F8F8F8;
  transition: 0.25s ease-out;
  padding-top: 5rem;
}

.calendar .cal-body .cal-cell:hover {
  overflow: visible;
}

.calendar .cal-body .cal-cell-weekly {
  position: relative;
  /* min-height:5em; */
  height: 3em;
  border-right: 1px solid var(--dim-grey);
  overflow: hidden;
  /* cursor: pointer; */
  /* background: var(--cal-neutral-color); */
  background: #F8F8F8;
  transition: 0.25s ease-out;
  padding-top: 1rem;
}

.calendar .cal-body .cal-cell-weekly:hover {
  overflow: visible;
}

.calendar .call-cell-weekly-is-past {
  background-color: #eee !important;
}

/* .calendar .cal-body .cal-cell:hover {
  background: var(--cal-bg-color);
  transition: 0.5s ease-out;
} */

.calendar .cal-body .cal-selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .cal-body .cal-row {
  border-bottom: 1px solid var(--dim-grey);
  /* height:5em; */
}

/* .calendar .cal-body .cal-row.cal-row-with-events {
  height:15em !important;
} */

.calendar .cal-body .cal-row:last-child {
  border-bottom: none;
}

.calendar .cal-body .cal-cell:last-child {
  border-right: none;
}

.calendar .cal-body .cal-cell .cal-number {
  position: absolute;
  /* font-size: 82.5%; */
  font-size: 182.5%;
  line-height: 1;
  top: .5em;
  left: .5em;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 100%;
}

.calendar .cal-body .cal-cell .cal-number .cal-header-buttons {
  display: flex;
}

.calendar .cal-body .cal-cell .cal-number .cal-header-buttons .new-post-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0 0.5rem;
}

.calendar .cal-body .cal-cell .cal-number .cal-header-buttons .new-post-button svg {
  height: 1.5rem;
  fill: var(--light-grey);
}

.calendar .cal-body .cal-cell .cal-number .cal-header-buttons .new-post-button:hover {
  background-color: var(--dim-grey);
}

.calendar .cal-body .cal-cell .cal-number .cal-header-buttons .new-post-button:hover svg {
  fill: var(--red);
}

/* .calendar .cal-body .cal-cell .cal-number .new-post-button {
  width: 100%;
  background-color: var(--dim-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 2rem;
  padding: 0.1rem 0;
  color: var(--grey);
  border-radius: 10px;
} */

/* .calendar .cal-body .cal-cell .cal-number .new-post-button:hover {
  opacity: 1;
}

.calendar .cal-body .cal-cell .cal-number .new-post-button svg {
  height: 1.8rem;
  fill: var(--grey);
} */


.calendar .cal-body .cal-cell-weekly .cal-hour {
  position: absolute;
  /* font-size: 82.5%; */
  font-size: 150.5%;
  line-height: 1;
  top: .2em;
  left: .5em;
  font-weight: 300;
  color: var(--grey);
}

.calendar .cal-body .cal-disabled {
  color: var(--cal-text-color-light);
  pointer-events: none;
}

/* .calendar .cal-body .cal-cell .cal-bg {
  font-weight: 700;
  line-height: 1;
  color: var(--cal-main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .cal-body .cal-cell:hover .cal-bg, .calendar .cal-body .cal-selected .cal-bg  {
  opacity: 0.05;
  transition: .5s ease-in;
} */

.calendar .cal-body .cal-col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

.calendar .cal-body .cal-col-weekly {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
  display: flex;
}

.cal-col-weekly .cal-header-buttons {
  display: flex;
}

.cal-col-weekly .cal-header-buttons .new-post-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0 0.5rem;
}

.cal-col-weekly .cal-header-buttons .new-post-button svg {
  height: 1.5rem;
  fill: var(--light-grey);
}

.cal-col-weekly.cal-header-buttons .new-post-button:hover {
  background-color: var(--dim-grey);
}

.cal-col-weekly .cal-header-buttons .new-post-button:hover svg {
  fill: var(--red);
}

/* .cal-col-weekly .new-post-button {
  width: 80%;
  background-color: var(--dim-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 2rem;
  padding: 0.1rem 0;
  color: var(--grey);
  border-radius: 10px;
  opacity: 0;
}

.cal-col-weekly .new-post-button:hover {
  opacity: 1;
}

.cal-col-weekly .new-post-button svg {
  height: 1.8rem;
  fill: var(--grey);
} */


.calendar .cal-today {
  border-top: 3px solid var(--blue);
  background-color: var(--dim-grey) !important;
  height: 100%;
  color: var(--blue);
}

.calendar .cal-today-weekly {
  height: 100%;
  color: var(--blue);
  font-weight: 700;
}

.calendar .cal-diff-month {
  background-color: #eee !important;
  height: 100%;
}

.calendar .cal-diff-month-number {
  color: var(--grey);
}

.cal-event {
  position: absolute;
  cursor: pointer;
  left: 10px;
  right: 10px;
  background-color: var(--white);
  padding: 0.2rem 0.5rem;
  overflow-x: hidden;
  border: 1px solid var(--dim-grey);
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);
}

.cal-event.draft-event {
  background: repeating-linear-gradient(45deg,
      var(--dim-grey),
      var(--dim-grey) 10px,
      var(--dimmer-grey) 10px,
      var(--dimmer-grey) 20px);
}

.cal-event:hover {
  z-index: 1000;
  /* height:fit-content; */
}

.add_event {
  opacity: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.add_event:hover {
  opacity: 1;
}

.published_dot {
  height: 10px;
  width: 10px;
  fill: var(--bright-green);
  margin-left: 2px;
}

.unpublished_dot {
  height: 10px;
  width: 10px;
  fill: var(--light-grey);
  margin-left: 2px;
}

.publishing_dot {
  height: 10px;
  width: 10px;
  fill: var(--blue);
  margin-left: 2px;
}

.failed_dot {
  height: 10px;
  width: 10px;
  fill: var(--red);
  margin-left: 2px;
}

.cal-event-wrapper {
  color: var(--reg-text);
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  overflow-x: hidden;
}

.cal-event-root {
  color: var(--reg-text);
}

.cal-media {
  position: relative;
  display: flex;
}

.cal-media img,
.cal-media video {
  width: 100%;
  object-fit: contain;
}

.cal-media .cal-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .60);
  /* border-radius: 50%; */
}

.cal-media .cal-video-overlay svg {
  height: 71%;
  /* width: 8rem; */
  fill: var(--white);
  cursor: pointer;
}

.cal-media .video_icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 8rem;
  width: 100%;
  background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  padding: 1rem;
}


.cal-media .video_icon svg {
  height: 3rem;
  width: 3rem;
  fill: var(--white);
}


/* DRAFTS TRAY */

.drafts_tray {
  width: 300px;
  height: 80vh;
  background-color: red;
}

/* FIRST COMMENT */

.first_comment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-wrap: normal;
}

.first_comment svg {
  height: 1.5rem;
  min-width: 1.5rem;
  fill: var(--grey);
  margin-right: 0.2rem;
}


.draft_event_accounts {
  width: 100%;
  border-top: 1px solid var(--dim-grey);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  padding: 0.5rem;
}